import { useEffect, useState } from 'react'
import { useGetDashboard, useGetLicense, useGetFolders } from '../../hooks'
import {
  AllReports,
  ApiReport,
  Folder,
  FolderItem,
  getLicenseState,
  ReportGroup,
} from '../../models'
import { Layout } from '../../components'
import { Stack, Typography } from '@mui/material'
import { Folders, FolderSkeletons } from '../reports/report-dashboard/folders'
import { ReportTiles } from '../reports/report-dashboard/report-tiles'
import { ReportTilesSkeleton } from '../reports/report-dashboard/report-tile-skeletons'

export function Dashboard() {
  const { data: license, isLoading: licenseLoading } = useGetLicense()
  const { data: homepageCards, isLoading: homepageLoading } = useGetDashboard()

  const myFolderReports = homepageCards?.reports?.ownedReports || []
  const sharedFolderReports = homepageCards?.reports?.sharedReports || []
  const reportGroupFolderReports = homepageCards?.reports?.groupedReports || []

  const {
    data: folders,
    isLoading: foldersLoading,
    isRefetching,
  } = useGetFolders()

  const [skeletonNumber, setSkeletonNumber] = useState(0)
  const [foldersSkeletonNumber, setFoldersSkeletonNumber] = useState(0)
  // const [homeReports, setHomeReports] = useState<AllReports | null>(null)
  const [foldersWithAutoGeneratedReports, setFoldersWithAutoGeneratedReports] =
    useState<Folder[]>([])

  const myReportGroups: ReportGroup[] =
    homepageCards?.reportGroups.filter(
      (group: ReportGroup) => group.showOnHome
    ) || []

  const homepageReports: AllReports = homepageCards?.reports || {
    ownedReports: [],
    sharedReports: [],
    groupedReports: [],
    licenseReports: [],
    defaultReports: [],
  }

  const { ownedReports, sharedReports, groupedReports } = homepageReports

  const allHomepageReports = [
    ...ownedReports,
    ...sharedReports,
    ...groupedReports,
  ]

  const allCards = [
    ...myReportGroups.map(group => ({
      ...group,
      type: 'report-group',
    })),
    ...allHomepageReports,
  ]?.filter(card => card?.showOnHome)

  const dedupedCards = allCards.filter(
    (card, index, self) => index === self.findIndex(t => t.id === card.id)
  )
  // Check License validity
  const validLicense = getLicenseState(license).valid
  // const setBookmarkState = useSetRecoilState(bookmarkAtom)

  useEffect(() => {
    //Check if we have the number of cards in local storage
    const cardsInLocalStorage = localStorage.getItem('dashboardCards')
    if (cardsInLocalStorage) {
      setSkeletonNumber(parseInt(cardsInLocalStorage))
    }
    //Check if we have the number of reports in local storage
    const foldersInLocalStorage = localStorage.getItem('folders')

    if (foldersInLocalStorage) {
      setFoldersSkeletonNumber(parseInt(foldersInLocalStorage) || 2)
    }
  }, [])

  useEffect(() => {
    if (
      homepageLoading ||
      !dedupedCards ||
      !dedupedCards.length ||
      foldersLoading
    ) {
      return
    }
    localStorage.setItem('dashboardCards', JSON.stringify(dedupedCards.length))
    localStorage.setItem('folders', JSON.stringify(folders?.length))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [homepageLoading, foldersLoading])

  useEffect(() => {
    if (foldersLoading || !folders) return

    const myReportsFolder: Folder = {
      id: 'my-reports-folder',
      name: 'My Reports',
      items: reportApiToFolderItems(myFolderReports),
      disableEdit: true,
    }

    const sharedReportsFolder: Folder = {
      id: 'shared-reports-folder',
      name: 'Shared Reports',
      items: reportApiToFolderItems(sharedFolderReports),
      disableEdit: true,
    }

    const showSharedReports = sharedReportsFolder.items?.length > 0
    const showMyReports = myReportsFolder.items?.length > 0

    localStorage.setItem(
      'folders',
      JSON.stringify(
        folders.length + (showSharedReports ? 1 : 0) + (showMyReports ? 1 : 0)
      )
    )
    const foldersWithReports = [
      ...folders,
      showMyReports ? myReportsFolder : null,
      showSharedReports ? sharedReportsFolder : null,
    ].filter(Boolean) as Folder[]

    const allReports = [
      ...myFolderReports,
      ...sharedFolderReports,
      ...reportGroupFolderReports,
    ]

    setFoldersWithAutoGeneratedReports(
      foldersWithReports.map(folder => ({
        ...folder,
        items: folder.items?.map(item => ({
          ...item,
          accessDenied: !allReports.find(
            r => r.id === item.id || r.id === item?.reportId
          ),
        })),
      }))
    )

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sharedFolderReports, myFolderReports, folders, foldersLoading])

  return (
    <Layout
      loading={licenseLoading}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'baseline',
        justifyContent: 'center',
        flexGrow: 1,
        width: '100%',
        minWidth: theme => theme.spacing(48),
      }}
    >
      <Stack
        sx={{
          mb: 8,
          width: '100vw',
          maxWidth: theme => theme.spacing(151.5),
          minWidth: theme => theme.spacing(48),
          px: 4.8,
        }}
        justifyContent='flex-start'
        alignItems='baseline'
        gap={2}
      >
        {!validLicense && (
          <>
            <Typography sx={{ mt: 10 }} variant='h6'>
              {`Your License expired on: ${license?.subscriptionEndDate}. Please contact your license owner to reactivate the license.`}
            </Typography>
          </>
        )}
        {validLicense && (
          <>
            {dedupedCards.length === 0 && !homepageLoading && (
              <Typography sx={{ mt: 10 }} variant='h6'>
                No items to display
              </Typography>
            )}
            {homepageLoading ? (
              <ReportTilesSkeleton
                numReports={skeletonNumber}
                title={'Analytic Types'}
              />
            ) : (
              <>
                {dedupedCards.length > 0 && (
                  <ReportTiles
                    title='Analytic Types'
                    isLoading={homepageLoading}
                    cards={dedupedCards}
                    folders={foldersWithAutoGeneratedReports}
                    isFavoritesLoading={foldersLoading || isRefetching}
                  />
                )}
              </>
            )}

            {foldersLoading || homepageLoading ? (
              <FolderSkeletons numFolders={foldersSkeletonNumber} />
            ) : (
              <Folders folders={foldersWithAutoGeneratedReports} />
            )}
          </>
        )}
      </Stack>
    </Layout>
  )
}

function reportApiToFolderItems(reports: ApiReport[]): FolderItem[] {
  return reports.map(report => ({
    id: report.id,
    name: report.name,
    type: report.type,
    description: report.description,
  }))
}
