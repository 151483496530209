import {
  CircularProgress,
  Stack,
  Theme,
  Typography,
  Divider,
} from '@mui/material'
import { makeStyles } from '@mui/styles'

import { ReportTile } from './report-tile'
import {
  useUpdateFolder,
  useCreateFolder,
  useAddItemsToFavorites,
  useRemoveItemFromFavorites,
} from '../../../hooks/mutations'

import { Folder } from '../../../models'

const useStyles = makeStyles((theme: Theme) => ({
  noReports: {
    marginTop: theme.spacing(5),
    fontWeight: 600,
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '300px',
  },
}))

export function ReportTiles(props: ReportTileProps) {
  const {
    isLoading,
    cards,
    title,
    folders,
    subtitle,
    isFavoritesLoading,
    favoritesFolder,
  } = props
  const classes = useStyles()

  const { mutateAsync: updateFolder } = useUpdateFolder()
  const { mutateAsync: createFolder } = useCreateFolder()
  const { mutateAsync: addToFavorites } = useAddItemsToFavorites()
  const { mutateAsync: removeFromFavorites } = useRemoveItemFromFavorites()

  const handleAddToFolder = async (
    folderId: string,
    report: any,
    newFolderName?: string
  ) => {
    if (!report.id) {
      return
    }

    if (newFolderName && folderId === '') {
      await createFolder({
        folderName: newFolderName,
        items: [
          { id: report.id, type: report.type || 'report', name: report.name },
        ],
      })
      return
    }

    const folder = folders.find(folder => folder.id === folderId)
    if (!folder) return
    await updateFolder({
      folderId: folder.id,
      items: [
        ...folder.items,
        { id: report.id, type: report.type || 'report', name: report.name },
      ],
    })
  }

  const handleFavorite = async (
    isFavorite: boolean,
    report: any //TODO: Add type
  ) => {
    if (isFavorite) {
      await removeFromFavorites({
        itemId: report.id,
      })
      return
    }

    await addToFavorites({
      item: {
        id: report.id,
        type: report.type || 'report',
        name: report.name,
        description: report.description,
      },
    })
  }

  if (isLoading) {
    return (
      <div className={classes.loading}>
        <CircularProgress />
      </div>
    )
  }

  if (!cards || !cards.length) {
    return (
      <Typography className={classes.noReports} variant='h3'>
        No reports to display
      </Typography>
    )
  }

  return (
    <Stack
      sx={{
        width: '100%',
        mt: 2,
      }}
    >
      {title && title !== '' && (
        <>
          <Stack
            direction='row'
            alignItems='baseline'
            gap={1}
            sx={{ height: '25px' }}
          >
            <Typography variant='h5' sx={{ fontWeight: 600, display: 'block' }}>
              {title}
            </Typography>
            {subtitle && (
              <Typography sx={{ fontWeight: 400 }}>({subtitle})</Typography>
            )}
          </Stack>
          <Divider
            sx={{
              mb: 1,
            }}
          />
        </>
      )}

      <Stack direction='row' gap={1} sx={{ flexWrap: 'wrap', width: '100%' }}>
        {cards.map(card => {
          return (
            <ReportTile
              key={`${title}-${card.id + card.name}`}
              title={card.name}
              isFavoritesLoading={isFavoritesLoading}
              description={card.description}
              id={card.id}
              type={card?.type || 'report'} //fixme: type is hardcoded
              imageUrl={card?.imageUrl}
              isFavorite={
                favoritesFolder
                  ? favoritesFolder?.items?.some(
                      (item: any) => item.id === card.id
                    )
                  : folders
                      ?.find(folder => folder.isFavorites)
                      ?.items?.some((item: any) => item.id === card.id)
              }
              handleFavoritesClicked={() => {
                handleFavorite(
                  favoritesFolder
                    ? favoritesFolder?.items?.some(
                        (item: any) => item.id === card.id
                      )
                    : folders
                        ?.find(folder => folder.isFavorites)
                        ?.items?.some((item: any) => item.id === card.id),
                  card
                )
              }}
              folders={folders?.filter(
                folder => !folder?.items.some(item => item.id === card.id)
              )}
              handleAddToFolder={(folderId: string, newFolderName?: string) => {
                handleAddToFolder(folderId, card, newFolderName)
              }}
            />
          )
        })}
      </Stack>
    </Stack>
  )
}

type ReportTileProps = {
  isLoading: boolean
  title: string
  cards: any[]
  subtitle?: string
  folders: Folder[]
  isFavoritesLoading: boolean
  favoritesFolder?: Folder
}
