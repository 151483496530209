import {
  alpha,
  debounce,
  Divider,
  Icon,
  IconButton,
  Skeleton,
  Stack,
  TextField,
  Theme,
  Typography,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import SearchIcon from '@mui/icons-material/Search'
import { useEffect, useState } from 'react'
import { DataColumnsProps, SingleDataColumnProps } from './types'
import { DataColumn } from '../../../../models'

export function DataColumns(props: DataColumnsProps) {
  const {
    title,
    dataColumns,
    setSelectedColumns,
    selectedColumns,
    loading,
    icon,
    showHelperText,
  } = props

  const [filteredColumns, setFilteredColumns] =
    useState<DataColumn[]>(dataColumns)

  const [search, setSearch] = useState('')

  useEffect(() => {
    setFilteredColumns(dataColumns)
  }, [dataColumns])

  const handleColumnSelection = (column: DataColumn) => {
    const index = selectedColumns.findIndex(
      selectedColumn => selectedColumn.id === column.id
    )
    const currentSelectedItems = [...selectedColumns]
    if (index === -1) {
      setSelectedColumns([...currentSelectedItems, column])
    } else {
      setSelectedColumns(
        currentSelectedItems.filter(
          selectedColumn => selectedColumn.id !== column.id
        )
      )
    }
  }

  const onSearch = (searchText: string) => {
    if (searchText === '') {
      setFilteredColumns(dataColumns)
    } else {
      setFilteredColumns(
        dataColumns.filter(column =>
          column.id.toLowerCase().includes(searchText.toLowerCase())
        )
      )
    }
  }

  const debouncedSearch = debounce(onSearch, 200)

  const SearchBar = () => {
    return (
      <TextField
        variant='standard'
        onChange={(
          event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
        ) => {
          setSearch(event.target.value)
          debouncedSearch(event.target.value)
        }}
        placeholder={'Search'}
        autoFocus
        value={search}
        sx={{
          '& .MuiInputBase-root': { borderBottom: 0 },
          ml: 1,
          p: '4px',
          alignSelf: 'center',
          justifyContent: 'flex-start',
          display: 'flex',
        }}
        InputProps={{
          endAdornment: (
            <Icon
              sx={{
                color: (theme: Theme) => theme.palette.text.secondary,
              }}
            >
              <SearchIcon fontSize='medium' />
            </Icon>
          ),
          disableUnderline: true,
        }}
      />
    )
  }

  return (
    <Stack direction={'column'} height='40%'>
      <Stack direction={'row'} alignItems={'center'} gap={1}>
        {icon}
        <Typography
          variant='subtitle1'
          fontWeight={'bold'}
          sx={{
            height: '28px',
          }}
        >
          {title}
        </Typography>
      </Stack>
      <Stack
        direction='column'
        sx={{
          border: '1px solid',
          borderColor: theme => theme.palette.divider,
          borderRadius: 1,
          overflow: 'hidden',
          height: '100%',
        }}
      >
        <SearchBar />
        <Divider sx={{ mb: 1 }} key={title + '-divider'} />
        <Stack
          key={title + '-stack'}
          direction={'column'}
          sx={{
            // height: '200px',
            width: '240px',
            p: 1,
            overflowY: 'auto',
            height: '100%',
          }}
          gap={1}
        >
          {loading ? (
            <DataColumnsSkeletonLoader />
          ) : (
            <>
              {filteredColumns.map((column, index) => (
                <SingleDataColumn
                  key={column.id + index + title}
                  column={column}
                  handleColumnSelection={handleColumnSelection}
                  selected={selectedColumns.some(
                    selectedColumn => selectedColumn.id === column.id
                  )}
                />
              ))}
            </>
          )}
        </Stack>
      </Stack>
      {showHelperText && (
        <Typography
          variant='caption'
          sx={{
            color: theme => theme.palette.error.main,
            mt: 1,
            fontSize: '11px',
          }}
        >
          Add a measure to preview the data
        </Typography>
      )}
    </Stack>
  )
}

function DataColumnsSkeletonLoader() {
  // 4 columns
  return (
    <>
      {Array.from({ length: 4 }).map((_, index) => (
        <Skeleton
          key={index + '-skeleton'}
          variant='rectangular'
          width='100%'
          height='40px'
          sx={{
            borderRadius: '12px',
          }}
        />
      ))}
    </>
  )
}

function SingleDataColumn(props: SingleDataColumnProps) {
  const { column, handleColumnSelection, selected } = props

  const [hovered, setHovered] = useState(false)
  const [showFullColumnName, setShowFullColumnName] = useState(false)

  useEffect(() => {
    if (hovered) {
      const timeout = setTimeout(() => {
        setShowFullColumnName(true)
      }, 2000)
      return () => clearTimeout(timeout)
    } else {
      setShowFullColumnName(false)
    }
  }, [hovered])

  return (
    <Stack
      onClick={() => handleColumnSelection(column, selected)}
      sx={{
        cursor: 'pointer',
        padding: 1,
        backgroundColor: theme =>
          selected
            ? alpha(theme.palette.primary.primaryColor400, 0.1)
            : 'transparent',
        borderRadius: '12px',
        '&:hover': {
          backgroundColor: theme =>
            alpha(theme.palette.primary.primaryColor400, 0.3),
        },
      }}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      alignItems='center'
      justifyContent={selected ? 'space-between' : 'flex-start'}
      direction='row'
    >
      <Typography
        sx={{
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
        }}
      >
        {showFullColumnName
          ? column.table + '[' + column.name + ']'
          : column.name}
      </Typography>
      {selected && hovered && (
        <IconButton
          size='small'
          color='primary'
          onClick={event => {
            event.stopPropagation()
            handleColumnSelection(column, selected)
          }}
          sx={{
            height: '20px',
          }}
        >
          <CloseIcon />
        </IconButton>
      )}
    </Stack>
  )
}
