import React from 'react'
import { Button, Theme, Typography } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'

import { RlsRow } from './rls-row'

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      row: {
        flexGrow: 1,
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
      },
      buttonRow: {
        flexGrow: 1,
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'flex-end',
      },
      title: {
        fontWeight: 700,
        width: '100%',
        marginBottom: theme.spacing(1),
      },
      iconPlaceholder: {
        width: theme.spacing(6),
      },
    }),
  { name: 'rls-fields' }
)

export function RlsFields(props: RlsEntriesProps) {
  const { roles, onRolesChange, username, ...rest } = props
  const classes = useStyles()

  function handleRole(value: string, index: number) {
    let currentRoles = [...roles]
    currentRoles[index] = value
    onRolesChange(currentRoles)
  }

  function handleAdd() {
    let currentRoles = [...roles]
    currentRoles.push('')
    onRolesChange(currentRoles)
  }

  function handleDelete(index) {
    let currentRoles = [...roles]
    currentRoles.splice(index, 1)
    onRolesChange(currentRoles)
  }

  if (!roles) {
    return (
      // empty
      <></>
    )
  }
  return (
    <div {...rest} className={classes.row}>
      <Typography variant='h6'>
        Row Level Security
        <Typography variant='body2'>
          <span className={classes.title}>User Property:</span> {username}
        </Typography>
      </Typography>
      <Typography className={classes.title} variant='body2'>
        Roles
      </Typography>
      {roles.map((role, index) => (
        <RlsRow
          key={`rls-row-${index}`}
          onRoleChange={handleRole}
          role={role}
          index={index}
          onDelete={index === 0 ? null : handleDelete}
        />
      ))}
      <div className={classes.buttonRow}>
        <Button color='secondary' onClick={handleAdd}>
          Add
        </Button>
      </div>
    </div>
  )
}

type RlsEntriesProps = React.HtmlHTMLAttributes<HTMLDivElement> & {
  roles: string[]
  username: string
  onRolesChange: (roles: string[]) => void
}
