import cs from 'classnames'
import {
  SettingsOutlined,
  EditOutlined,
  EditOff,
  Settings,
  SaveAsOutlined,
} from '@mui/icons-material'
import RotateLeftIcon from '@mui/icons-material/RotateLeft'
import {
  IconButton,
  Stack,
  Theme,
  Tooltip,
  Toolbar,
  SvgIcon,
} from '@mui/material'
import { useState } from 'react'
import { bookmarkAtom } from '../../../state'
import { useRecoilState } from 'recoil'
import { createStyles, makeStyles } from '@mui/styles'
import ReportSettingsDialog from '../../../components/layout/report-settings-dialog'
import { useGetDashboard, useUpdateReportSettings } from '../../../hooks'
import {
  AllReports,
  REPORT_TYPES,
  ReportSettings,
  WorkspaceInfo,
} from '../../../models'
import { ReportCopyDialog } from './copy-report-dialog'

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      appBar: {
        transition: theme.transitions.create(['margin', 'width'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        zIndex: 1301,
        borderBottom: '1px solid #e0e0e0',
      },
      toolbar: {
        width: '100%',
        height: '50px',
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        justifyContent: 'space-between',
        alignItems: 'center',
      },
      menuButton: {
        width: '40px',
        height: '40px',
        color: theme.palette.primary.primaryColor400,
        marginRight: theme.spacing(2),
        '&:hover, &.Mui-focusVisible': {
          color: theme.palette.primary.primaryColor400,
        },
      },
      bookmarkButton: {
        marginLeft: theme.spacing(2),
        '&:hover, &.Mui-focusVisible': {
          color: theme.palette.primary.primaryColor400,
        },
      },
      leftSide: {
        display: 'flex',
        alignItems: 'center',
      },
      rightSide: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: 'auto',
      },
      logo: {
        marginLeft: theme.spacing(0.5),
        display: 'flex',
        alignItems: 'center',
      },
    }),
  { name: 'report-bar' }
)

export function ReportOptionsBar(props: ReportOptionsBarProps) {
  const {
    reportId,
    reportType,
    isOwner,
    editMode,
    toggleEditMode,
    showCopyDialog,
    setShowCopyDialog,
    hasRendered,
    setCopyReport,
  } = props

  const classes = useStyles()
  const [bookmarkState, setBookmarkState] = useRecoilState(bookmarkAtom)
  const [showSettingsDialog, setShowSettingsDialog] = useState(false)

  const { data: dashboard } = useGetDashboard()

  const reports: AllReports = dashboard?.reports
  const ownedReports = reports?.ownedReports || []
  const sharedReports = reports?.sharedReports || []
  const defaultReports = reports?.defaultReports || []
  const groupedReports = reports?.groupedReports || []
  const allReports = [
    ...ownedReports,
    ...sharedReports,
    ...defaultReports,
    ...groupedReports,
  ]
  const selectedReport =
    allReports?.find(report => report.id === reportId) || null

  const isPaginatedReport =
    reportType === REPORT_TYPES.CUSTOM_PAGINATED ||
    reportType === REPORT_TYPES.PAGINATED_REPORT

  const {
    mutateAsync: updateReportSettings,
    isLoading: updatingReportSettings,
  } = useUpdateReportSettings()

  const handleBookmarksClick = () => {
    setBookmarkState({
      ...bookmarkState,
      panelOpen: !bookmarkState.panelOpen,
    })
  }

  const BookmarkButton = (
    <Tooltip title='Bookmarks' arrow={true}>
      <IconButton
        className={cs(classes.menuButton)}
        onClick={handleBookmarksClick}
        aria-label='bookmark'
        disabled={!hasRendered}
      >
        <SvgIcon
          sx={{
            color: theme => theme.palette.primary.primaryColor400,
            fontSize: '18px',
          }}
        >
          {!bookmarkState.selectedBookmark ? (
            <svg
              xmlns='http://www.w3.org/2000/svg'
              fill='none'
              viewBox='0 0 21.08 20'
            >
              <path
                fill-rule='evenodd'
                clip-rule='evenodd'
                d='M13 18.8333L15 20V17.6846V12.375H20.2C20.6832 12.375 21.075 11.9832 21.075 11.5C21.075 11.0168 20.6832 10.625 20.2 10.625H15H13H9.7C9.21675 10.625 8.825 11.0168 8.825 11.5C8.825 11.9832 9.21675 12.375 9.7 12.375H13V16.5179L8.50774 13.8974L7.5 13.3096L6.49226 13.8974L2 16.5179V2H6.99801L7 2H15V1.875C15 0.839453 14.1605 0 13.125 0H1.875C0.839453 0 0 0.839453 0 1.875V17.6846V20L2 18.8333L7.5 15.625L13 18.8333ZM13 8.875H9.7C9.21675 8.875 8.825 8.48325 8.825 8C8.825 7.51675 9.21675 7.125 9.7 7.125H13H15H20.2C20.6832 7.125 21.075 7.51675 21.075 8C21.075 8.48325 20.6832 8.875 20.2 8.875H15H13ZM20.2 5.38C20.686 5.38 21.08 4.98601 21.08 4.5C21.08 4.01399 20.686 3.62 20.2 3.62L15 3.62H13L9.7 3.62C9.21399 3.62 8.82 4.01399 8.82 4.5C8.82 4.98601 9.21399 5.38 9.7 5.38H20.2Z'
                fill='currentColor'
              ></path>
            </svg>
          ) : (
            <svg
              xmlns='http://www.w3.org/2000/svg'
              fill='none'
              viewBox='0 0 21.08 20'
            >
              <path
                fill-rule='evenodd'
                clip-rule='evenodd'
                d='M0 20V1.875C0 0.839453 0.839453 0 1.875 0H13.125C14.1605 0 15 0.839453 15 1.875H9C8.51675 1.875 8.125 2.26675 8.125 2.75C8.125 3.23325 8.51675 3.625 9 3.625H9.60565C9.63665 3.6217 9.66813 3.62 9.70001 3.62L20.2 3.62C20.686 3.62 21.08 4.01399 21.08 4.5C21.08 4.98601 20.686 5.38 20.2 5.38H9.70001C9.66813 5.38 9.63665 5.3783 9.60565 5.375H9C8.51675 5.375 8.125 5.76675 8.125 6.25C8.125 6.73325 8.51675 7.125 9 7.125H9.70001H15H20.2C20.6833 7.125 21.075 7.51675 21.075 8C21.075 8.48325 20.6833 8.875 20.2 8.875H15H9.70001H9C8.51675 8.875 8.125 9.26675 8.125 9.75C8.125 10.2332 8.51675 10.625 9 10.625H9.70001H15H20.2C20.6833 10.625 21.075 11.0168 21.075 11.5C21.075 11.9832 20.6833 12.375 20.2 12.375H15V20L7.5 15.625L0 20Z'
                fill='currentColor'
              ></path>
            </svg>
          )}
        </SvgIcon>
      </IconButton>
    </Tooltip>
  )

  const ResetButton = (
    <Tooltip title='Reset Report Filters' arrow={true}>
      <IconButton
        color='inherit'
        aria-label='reset'
        size='large'
        disabled={!hasRendered}
        onClick={() => {
          setBookmarkState({
            ...bookmarkState,
            selectedBookmark: null,
            resetReportFilters: true,
          })
        }}
      >
        <RotateLeftIcon />
      </IconButton>
    </Tooltip>
  )

  const SettingsButton = (
    <Tooltip title='Report Settings' arrow={true}>
      <IconButton
        color='inherit'
        aria-label='reset'
        size='large'
        onClick={() => setShowSettingsDialog(true)}
      >
        {showSettingsDialog ? <Settings /> : <SettingsOutlined />}
      </IconButton>
    </Tooltip>
  )

  const SettingsDialog = (
    <ReportSettingsDialog
      open={showSettingsDialog}
      onClose={() => setShowSettingsDialog(false)}
      filterPaneEnabled={selectedReport?.filterPaneEnabled}
      showPageNavigation={selectedReport?.showPageNavigation}
      saving={updatingReportSettings}
      setReportSettings={async (reportSettings: ReportSettings) => {
        await updateReportSettings({
          id: selectedReport.id,
          name: selectedReport.name,
          filterPaneEnabled: reportSettings?.filterPaneEnabled || false,
          showPageNavigation: reportSettings?.showPageNavigation || false,
        })
      }}
    />
  )

  const EditModeButton = (
    <Tooltip
      title={editMode ? 'Exit Edit Mode' : 'Enter Edit Mode'}
      arrow={true}
    >
      <IconButton
        color='inherit'
        aria-label='reset'
        size='large'
        onClick={toggleEditMode}
        disabled={!hasRendered && reportType !== REPORT_TYPES.CUSTOM_PAGINATED}
      >
        {editMode ? (
          <EditOff
            sx={{
              color: theme => theme.palette.primary.main,
            }}
          />
        ) : (
          <EditOutlined />
        )}
      </IconButton>
    </Tooltip>
  )

  const SaveAsButton = (
    <Tooltip title={'Save a Copy'} arrow={true}>
      <IconButton
        sx={{ color: theme => theme.palette.primary.primaryColor400 }}
        aria-label='reset'
        size='large'
        onClick={() => setShowCopyDialog(true)}
        disabled={!hasRendered}
      >
        <SaveAsOutlined />
      </IconButton>
    </Tooltip>
  )

  return (
    <Stack direction='row'>
      <Toolbar
        sx={{
          minHeight: '35px',
          width: 'calc(100%)',
          height: '35px',
          px: 3,
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingRight: '0 !important',
          color: theme => theme.palette.primary.primaryColor400,
        }}
      >
        <Stack direction='row' gap={0} className={classes.rightSide}>
          {!editMode &&
            selectedReport?.type === REPORT_TYPES.REPORT &&
            SaveAsButton}
          {isOwner &&
            selectedReport?.type !== REPORT_TYPES.PAGINATED_REPORT &&
            EditModeButton}
          {isOwner && !isPaginatedReport && SettingsButton}
          {!isPaginatedReport && ResetButton}
          {!isPaginatedReport && BookmarkButton}
        </Stack>
      </Toolbar>
      {SettingsDialog}
      <ReportCopyDialog
        setCopyReport={setCopyReport}
        onClose={() => setShowCopyDialog(false)}
        isOpen={showCopyDialog}
      />
    </Stack>
  )
}

type ReportOptionsBarProps = {
  reportId: string
  isOwner: boolean
  isAdmin: boolean
  editMode: boolean
  toggleEditMode
  showCopyDialog
  hasRendered: boolean
  bookmarkId?: string
  setShowCopyDialog
  setCopyReport: (report: {
    name: string
    description: string
    shareWithLicense: boolean
    workspace: WorkspaceInfo
  }) => void
  reportType: string
}
