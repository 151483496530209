import { useEffect, useState } from 'react'
import {
  ListItemText,
  Collapse,
  List,
  alpha,
  Theme,
  IconButton,
  ListItemButton,
} from '@mui/material'
import { NavigationLink } from './navigation-link'
import { useHistory } from 'react-router-dom'
import { FolderNav } from './folder-nav'
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined'
import { highlightText } from './highlight-text'
import { ReportGroupType } from './types'
import { makeStyles, createStyles } from '@mui/styles'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    listItem: {
      fontSize: theme.typography.fontSize,
      paddingLeft: theme.spacing(1),
      '&:hover': {
        backgroundColor: alpha(theme.palette.primary.primaryColor100, 1),
      },
      borderTop: '1px solid #F2F2F2',
      height: '30px',
      overflow: 'hidden',
    },
    listItemText: {
      pl: 0,
      fontSize: theme.typography.fontSize,
      fontWeight: 600,
    },
  })
)

export function ReportGroupNav(props: ReportGroupNavProps) {
  const { reportGroup, searchText } = props
  const { name } = reportGroup

  const classes = useStyles()
  const history = useHistory()

  const [open, setOpen] = useState(searchText.length > 1)

  useEffect(() => {
    if (searchText?.length > 2) {
      setOpen(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText])

  const handleDropdownClick = e => {
    e.stopPropagation()
    setOpen(!open)
  }

  const handleClick = e => {
    e.stopPropagation()
    history.push(`/report-groups/${reportGroup.id}`)
  }

  const isSelected = history.location.pathname.includes(
    `/report-groups/${reportGroup.id}`
  )
  return (
    <List disablePadding>
      <ListItemButton
        className={classes.listItem}
        sx={{
          backgroundColor: theme =>
            isSelected
              ? alpha(theme.palette.primary.primaryColor100, 1)
              : 'inherit',
        }}
      >
        <IconButton onClick={handleDropdownClick}>
          {open ? <ExpandMoreIcon /> : <KeyboardArrowRightIcon />}
        </IconButton>
        <ListItemText
          onClick={handleClick}
          className={classes.listItemText}
          disableTypography={true}
          sx={{ fontSize: '10px' }}
          primary={highlightText(name, searchText)}
        />
      </ListItemButton>
      <Collapse in={open} timeout='auto' unmountOnExit>
        <List component='div' sx={{ padding: 0 }}>
          {reportGroup?.reports
            ?.filter(report => report?.isKeyReport)
            ?.map((item, index) => (
              <NavigationLink
                key={`navlink-${item.id}-${index}`}
                path={`/reports/${item.id}`}
                description={item.description}
                dense
                searchText={searchText}
                sx={{ paddingLeft: 0.5 }}
              >
                {item.name}
              </NavigationLink>
            ))}
          {reportGroup?.folders?.map(folder => (
            <FolderNav
              title={folder.name}
              items={folder.items}
              key={folder.name}
              icon={<FolderOutlinedIcon />}
              subFolder
              searchText={searchText}
            />
          ))}
        </List>
      </Collapse>
    </List>
  )
}

type ReportGroupNavProps = {
  reportGroup: ReportGroupType
  searchText: string
}
