import React, { useEffect, useRef } from 'react'
import {
  ClickAwayListener,
  IconButton,
  MenuItem,
  MenuList,
  Popover,
} from '@mui/material'
import { MoreVert as MoreVertIcon } from '@mui/icons-material'

import { ReactStateSetter } from '../../../../models'

export function OverflowMenu(props: OverflowMenuProps) {
  const {
    onDelete,
    onEdit,
    isOpen,
    editButtonName,
    deleteButtonName,
    setIsOpen,
    onUpdate,
    onAddToFolderClicked,
    onThirdButton,
    thirdButtonName,
    sx,
    setRef,
  } = props
  const anchorRef = useRef<HTMLButtonElement>()

  useEffect(() => {
    if (!setRef) return
    setRef(anchorRef)
  }, [setRef])

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault()
      setIsOpen(false)
    }
  }

  return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={e => {
          e.stopPropagation()
          setIsOpen(open => !open)
        }}
        color='primary'
        aria-haspopup='true'
        size='large'
        data-testid='open-overflow-menu'
        style={{
          ...sx,
        }}
      >
        <MoreVertIcon sx={{ color: 'inherit' }} />
      </IconButton>
      <Popover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={isOpen}
        anchorEl={anchorRef.current}
      >
        <ClickAwayListener onClickAway={() => setIsOpen(false)}>
          <MenuList
            autoFocusItem={isOpen}
            id='menu-list-grow'
            onKeyDown={handleListKeyDown}
            sx={{ borderRadius: 15 }}
          >
            {onEdit && (
              <MenuItem
                onClick={e => {
                  setIsOpen(false)
                  onEdit(e)
                }}
                data-testid='edit'
              >
                {editButtonName || 'Edit'}
              </MenuItem>
            )}
            {onUpdate && (
              <MenuItem
                onClick={e => {
                  setIsOpen(false)
                  onUpdate(e)
                }}
                data-testid='edit'
              >
                {'Update'}
              </MenuItem>
            )}

            {onAddToFolderClicked && (
              <MenuItem
                onClick={e => {
                  setIsOpen(false)
                  onAddToFolderClicked(e)
                }}
                data-testid='edit'
              >
                {'Add to folder'}
              </MenuItem>
            )}
            {onThirdButton && (
              <MenuItem
                onClick={e => {
                  setIsOpen(false)
                  onThirdButton(e)
                }}
              >
                {thirdButtonName || 'Edit Details'}
              </MenuItem>
            )}
            {onDelete && (
              <MenuItem
                onClick={e => {
                  setIsOpen(false)
                  onDelete(e)
                }}
              >
                {deleteButtonName || 'Delete'}
              </MenuItem>
            )}
          </MenuList>
        </ClickAwayListener>
      </Popover>
    </>
  )
}

type OverflowMenuProps = {
  isOpen: boolean
  setIsOpen: ReactStateSetter<boolean>
  editButtonName?: string
  deleteButtonName?: string
  thirdButtonName?: string
  onDelete?: (e) => void
  onEdit?: (e) => void
  onThirdButton?: (e) => void
  onUpdate?: (e) => void
  onAddToFolderClicked?: (e) => void
  sx?: React.CSSProperties
  setRef?: (ref: React.RefObject<HTMLButtonElement>) => void
}
