import {
  Box,
  CircularProgress,
  IconButton,
  Link,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material'
import { DataColumns } from './data-columns'
import {
  DataColumnWithFilters,
  FILTER_OPTIONS,
  FILTER_TYPES,
  OnDragEndType,
} from './types'
import { useEffect, useState } from 'react'
import CustomTable from './custom-table'
import { EditableText } from './editable-text-field'
import SaveAsOutlinedIcon from '@mui/icons-material/SaveAsOutlined'
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined'
import {
  useGetCustomReportItems,
  useGetLicense,
  usePostColumnValuesQuery,
  usePostDaxQuery,
  useUpdatePaginatedReport,
} from '../../../../hooks'
import {
  ApiReport,
  ColumnItemType,
  DataColumn,
  FilterItemType,
  MeasureItemType,
  REPORT_TYPES,
} from '../../../../models'
import { generateDAXQuery } from '../generate-rdl-file/generate-dax-query'
import { CustomReportFilters } from '../filters/filters'
import SelectedColumns from './selected-columns'
import MdcCalculator from '@meronex/icons/mdc/MdcCalculator'
import { AddPaginatedReportForm } from '../create-paginated-report-dialog/create-paginated-report-dialog'
import { ToastNotification } from '../../../../components/common/Toast'
import { generateReportRDL } from '../generate-rdl-file/generate-rdl-report'
import { DataColumnTypes } from '../generate-rdl-file/types'

type CustomReportTableProps = {
  currentReportId: string
  name: string
  report?: ApiReport
}
const illegalNameChars = [
  '/',
  '\\',
  '?',
  '%',
  '*',
  ':',
  '|',
  '"',
  "'",
  '<',
  '>',
  '-',
  '=',
  '.',
]
export function CustomReportTable(props: CustomReportTableProps) {
  const { currentReportId, report } = props
  const [selectedColumns, setSelectedColumns] = useState<DataColumn[]>([])

  const [toastOpen, setToastOpen] = useState(false)
  const [toastVariant, setToastVariant] = useState<
    'primary' | 'success' | 'warning' | 'error'
  >('success')
  const [toastMessage, setToastMessage] = useState<React.ReactElement | string>(
    ''
  )

  const [reportId, setReportId] = useState('')

  const { data: license } = useGetLicense()

  const [reportName, setReportName] = useState(report?.name || 'New Report')
  const [openPaginatedReportDialog, setOpenPaginatedReportDialog] =
    useState(false)
  const { mutateAsync: updateReport, isLoading: isUpdatingReport } =
    useUpdatePaginatedReport()
  const {
    data: reportItems,
    isLoading: loadingReportItems,
    refetch: refetchCustomReportItems,
    isRefetching: isRefetchingReportItems,
  } = useGetCustomReportItems({
    reportId: currentReportId,
  })

  const { mutateAsync: executeQuery, isLoading: gettingRowData } =
    usePostDaxQuery()

  const { mutateAsync: getRowValues, isLoading: gettingRowValues } =
    usePostColumnValuesQuery()

  const [reportItemsWithFilters, setReportItemsWithFilters] = useState<
    DataColumnWithFilters[]
  >([])

  useEffect(() => {
    if (!reportId) return
    setToastVariant('success')
    setToastOpen(true)
    setToastMessage(
      <Typography
        variant='subtitle2'
        noWrap
        color='textPrimary'
        sx={{ color: 'white' }}
      >
        Click{' '}
        <Link
          href={`/reports/${reportId}`}
          sx={{
            color: 'white',
            textDecoration: 'underline',
          }}
        >
          here
        </Link>{' '}
        to view the report
      </Typography>
    )
  }, [reportId])

  const [sortValFromTable, setSortValFromTable] = useState<{
    colId: string
    sort: 'asc' | 'desc'
  } | null>(report?.sortVal ? JSON.parse(report?.sortVal) : null)

  const [filterValFromTable, setFilterValFromTable] = useState<{
    colId: string
    operator: string
    value: any
  } | null>(null)

  const [colsIncludeMeasure, setColsIncludeMeasure] = useState(false)

  useEffect(() => {
    refetchCustomReportItems()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportId])

  useEffect(() => {
    if (
      !report?.selectedColumns ||
      !reportItems ||
      reportItems?.columns?.length === 0
    ) {
      return
    }

    const selectedColIds = JSON.parse(report?.selectedColumns)

    const formattedFilters = report?.filters ? JSON.parse(report?.filters) : []

    const allReportItems = [
      ...reportItems?.columns?.map(c => {
        return {
          name: c.columnName,
          type: 'column',
          id: c.daxReference,
          dataType: c.dataType,
          table: c.table,
        }
      }),
      ...reportItems?.measures?.map(m => {
        return {
          name: m.measureName,
          type: 'measure',
          id: m.daxReference,
          dataType: m.dataType,
          table: m.table,
        }
      }),
    ]

    const mappedSelectedDataColumns: DataColumn[] = selectedColIds?.map(
      selectedColId => allReportItems?.find(i => i?.id === selectedColId)
    )

    setSelectedColumns(mappedSelectedDataColumns)

    setReportItemsWithFilters(formattedFilters)
  }, [report, reportItems])

  const handleSave = async () => {
    if (!report || !reportItems) return

    const rdlStringFile = generateReportRDL({
      selectedColumns: selectedColumns,
      filters: reportItemsWithFilters,
      datasetId: reportItems?.datasetId || '',
      reportName: reportName,
      sortVal: sortValFromTable,
      filterVal: filterValFromTable,
    })

    const reportToCreateOrUpdate = {
      id: report?.id || undefined,
      name: reportName,
      selectedColumns: selectedColumns?.map(col => col.id),
      filters: JSON.stringify(reportItemsWithFilters),
      appRegNodeId: report.appRegistrationNodeId,
      workspaceId: report?.pbiWorkspaceId,
      profileId: report?.profileId,
      file: rdlStringFile,
      licenseId: license.id,
      datasetId: reportItems?.datasetId || '',
      description: report?.description || '',
      sortVal: JSON.stringify(sortValFromTable),
    }

    await updateReport(reportToCreateOrUpdate)

    setReportId(report.id)
  }

  const getUniqueRowValues = async (
    column: DataColumn,
    search?: string
  ): Promise<string[]> => {
    const rows = await getRowValues({
      reportId: currentReportId,
      columnId: column.id,
      search,
    })

    return rows
  }

  useEffect(() => {
    if (!reportItems || !report) return

    if (report?.filters) return

    const formattedFilters: DataColumnWithFilters[] =
      reportItems?.filters?.map((f: FilterItemType) => ({
        name: f.name,
        type: f.type,
        id: f.daxReference,
        dataType: f.dataType,
        table: f.table,
        filterType: FILTER_TYPES.BASIC,
      })) || []

    setReportItemsWithFilters(
      formattedFilters.filter(f => f.dataType !== FILTER_OPTIONS.DATE_TIME)
    )
  }, [reportItems, report])

  //When selected cols changes, add the col to the reportItemsWithFilters if it is not already there
  useEffect(() => {
    if (!selectedColumns.length) return

    const newReportItemsWithFilters = [...reportItemsWithFilters]

    selectedColumns
      ?.filter(
        col =>
          col.type === DataColumnTypes.COLUMN &&
          col.dataType !== FILTER_OPTIONS.DATE_TIME
      )
      ?.forEach(col => {
        if (newReportItemsWithFilters.some(f => f.id === col.id)) return

        newReportItemsWithFilters.push({
          name: col.name,
          type: col.type,
          id: col.id,
          dataType: col.dataType,
          table: col.table,
          filterType: FILTER_TYPES.BASIC,
        })
      })

    setReportItemsWithFilters(newReportItemsWithFilters)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedColumns])

  const [rowData, setRowData] = useState<any[]>([])

  const getReportData = async (query: string) => {
    try {
      const queryRes = await executeQuery({
        reportId: currentReportId,
        query,
      })

      const formattedRows = formatRows(queryRes?.data || {})
      setRowData(formattedRows)
    } catch (error) {
      // set toast error

      const message = error.response.data.errorMessage || 'An error occurred'

      setToastVariant('error')
      setToastMessage(message)
      setToastOpen(true)
    }
  }

  const formatRows = (data: {
    [key: number]: any
  }): Array<{ id: number; [key: string]: any }> => {
    let newRows = Object.entries(data).map(([_key, value], index) => {
      const formattedRow: any = { id: index } // Initialize with id property
      Object.keys(value).forEach((innerKey: string) => {
        formattedRow[innerKey] = value[innerKey] // Copy each property from value to formattedRow
      })
      return formattedRow
    })

    // If the column is of type Date, convert the string to a Date object
    selectedColumns.forEach((column: DataColumn) => {
      if (column.dataType === FILTER_OPTIONS.DATE_TIME) {
        newRows.forEach(row => {
          if (Object.keys(row).length <= 1) return
          const key = column?.id?.replaceAll("'", '')
          if (!row[key]) return
          row[key] = new Date(row[key])?.toISOString().split('T')[0]
        })
      }
      if (column.dataType === FILTER_OPTIONS.DOUBLE) {
        newRows.forEach(row => {
          const key = column?.id?.replaceAll("'", '')
          row[key] = Math.round(row[key] * 100) / 100
        })
      }
    })

    return newRows?.filter(c => {
      const values = Object.values(c).splice(1)
      return values.some(v => v !== undefined && !isNaN(v as any))
    })
  }

  useEffect(() => {
    if (!selectedColumns.length) return

    const includesMeasures = selectedColumns.some(
      col => col.type === DataColumnTypes.MEASURE
    )

    setColsIncludeMeasure(includesMeasures)

    if (!includesMeasures) {
      setRowData([])
      return
    }

    const query = generateDAXQuery({
      filters: reportItemsWithFilters,
      currentColumns: selectedColumns,
      sortCol: sortValFromTable,
    })

    if (!query) return

    getReportData(query)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportItemsWithFilters, selectedColumns?.length, sortValFromTable])

  const handleDragEnd = (result: OnDragEndType) => {
    const oldIndex = result?.source?.index
    const newIndex = result?.destination?.index

    if (oldIndex === undefined || newIndex === undefined) return

    let newColumns = [...selectedColumns]
    const [columnToMove] = newColumns.splice(oldIndex, 1)
    newColumns.splice(newIndex, 0, columnToMove)

    setSelectedColumns(newColumns)
  }

  const onFilterModelChange = (_model: any) => {
    const filter = _model?.items?.[0]
    if (!filter) {
      setFilterValFromTable(null)
      return
    }
    setFilterValFromTable({
      colId: filter?.field || '',
      operator: filter?.operator || '',
      value: filter?.value || '',
    })
  }

  const onSortModelChange = (_model: any) => {
    const sortVal = _model?.[0]
    if (!sortVal) {
      setSortValFromTable(null)
      return
    }

    const isMeasure =
      sortVal.field[0] === '[' &&
      sortVal.field[sortVal.field.length - 1] === ']'

    const correspondingCol = selectedColumns.find(col => {
      const matchString = isMeasure
        ? `[${col.name}]`
        : `${col.table}[${col.name}]`

      return matchString === sortVal?.field
    })

    setSortValFromTable({
      colId: correspondingCol?.id || '',
      sort: sortVal?.sort === 'asc' ? 'asc' : 'desc',
    })
  }

  function ReportButtons() {
    return (
      <Stack
        direction='row'
        alignItems='center'
        gap={1}
        width='20%'
        justifyContent={'flex-end'}
      >
        <Tooltip title='Save As' placement='top' arrow={true}>
          <IconButton
            onClick={() => {
              setOpenPaginatedReportDialog(true)
            }}
            disabled={isUpdatingReport || !colsIncludeMeasure}
          >
            <SaveAsOutlinedIcon />
          </IconButton>
        </Tooltip>
        {report?.type === REPORT_TYPES.CUSTOM_PAGINATED && (
          <span>
            <Tooltip title='Save' placement='top' arrow={true}>
              <IconButton
                onClick={() => {
                  handleSave()
                }}
                disabled={isUpdatingReport || !colsIncludeMeasure}
              >
                <SaveOutlinedIcon />
              </IconButton>
            </Tooltip>
          </span>
        )}
      </Stack>
    )
  }

  return (
    <>
      {isUpdatingReport && (
        <Box
          sx={{
            backgroundColor: 'rgba(0,0,0, 0.6)',
            zIndex: 1000,
            width: '100%',
            height: '100%',
            position: 'absolute',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CircularProgress
            sx={{
              position: 'absolute',
              top: '30%',
              left: '50%',
              transform: 'translate(-50%, -50%) rotate(180deg)',
              background: 'transparent',
              color: 'white',
            }}
            size={60}
          />
        </Box>
      )}

      <Stack
        direction='row'
        gap={2}
        alignItems='flex-start'
        sx={{
          display: 'flex',
          height: '100%',
          width: '100%',
          padding: theme => theme.spacing(3),
        }}
      >
        <ToastNotification
          open={toastOpen}
          onClose={() => setToastOpen(false)}
          message={toastMessage}
          variant={toastVariant}
          autoTimeout={8000}
        />

        <Stack
          direction='column'
          gap={2}
          sx={{
            mt: '104px',
            height: '100%',
          }}
        >
          <DataColumns
            key='Dimensions'
            title='Dimensions'
            dataColumns={
              reportItems?.columns?.map((c: ColumnItemType) => ({
                name: c.columnName,
                type: 'column',
                id: c.daxReference,
                dataType: c.dataType,
                table: c.table,
              })) || []
            }
            loading={loadingReportItems || isRefetchingReportItems}
            selectedColumns={selectedColumns}
            setSelectedColumns={setSelectedColumns}
          />
          <DataColumns
            key='Measures'
            title='Measures'
            loading={loadingReportItems || isRefetchingReportItems}
            dataColumns={
              reportItems?.measures?.map((m: MeasureItemType) => ({
                name: m.measureName,
                type: 'measure',
                id: m.daxReference,
                dataType: m.dataType,
                table: m.table,
              })) || []
            }
            icon={<MdcCalculator />}
            selectedColumns={selectedColumns}
            setSelectedColumns={setSelectedColumns}
            showHelperText={selectedColumns?.length > 0 && !colsIncludeMeasure}
          />
        </Stack>
        <Stack
          direction='column'
          gap={2}
          width={'80%'}
          sx={{
            flexShrink: 1,
            height: '100%',
            overflow: 'auto',
          }}
        >
          <CustomReportFilters
            filterOptions={reportItemsWithFilters}
            setFilters={(filters: DataColumnWithFilters[]) => {
              setReportItemsWithFilters(filters)
            }}
            getRowValues={getUniqueRowValues}
            gettingRowValues={gettingRowValues}
          />
          <Stack
            direction={'row'}
            justifyContent={'space-between'}
            alignItems={'center'}
            height='20px'
            sx={{
              width: '100%',
            }}
          >
            <EditableText
              name={reportName}
              setName={setReportName}
              illegalChars={illegalNameChars}
            />
            <ReportButtons />
          </Stack>
          <SelectedColumns
            columns={selectedColumns}
            onDelete={(col: DataColumn) => {
              setSelectedColumns(selectedColumns.filter(c => c.id !== col.id))
            }}
            onDragEnd={(result: OnDragEndType) => {
              handleDragEnd(result)
            }}
          />
          <CustomTable
            columns={selectedColumns}
            rows={rowData || []}
            onFilterModelChange={onFilterModelChange}
            onSortModelChange={onSortModelChange}
            loading={
              loadingReportItems || gettingRowData || isRefetchingReportItems
            }
            filterOptions={reportItemsWithFilters}
          />
        </Stack>

        {openPaginatedReportDialog && (
          <AddPaginatedReportForm
            open={openPaginatedReportDialog}
            onClose={() => setOpenPaginatedReportDialog(false)}
            onSave={() => setOpenPaginatedReportDialog(false)}
            name={reportName}
            selectedColumns={selectedColumns}
            datasetId={reportItems?.datasetId || ''}
            filters={reportItemsWithFilters}
            setReportId={(newReportId: string) => setReportId(newReportId)}
            report={report}
            datasetWorkspaceId={reportItems?.workspaceId || ''}
            illegalNameChars={illegalNameChars}
            sortVal={sortValFromTable}
            currentReportId={currentReportId}
          />
        )}
      </Stack>
    </>
  )
}
