import { useParams } from 'react-router-dom'
import {
  useGetDashboard,
  useGetFolders,
  useGetLicense,
  useGetReportGroupMembers,
} from '../../../hooks'
import { useEffect, useState } from 'react'
import { getLicenseState } from '../../../models'
import { Layout } from '../../../components'
import { Stack, Typography, Divider } from '@mui/material'
import { ReportTiles } from '../../reports/report-dashboard/report-tiles'
import {
  Folders,
  FolderSkeletons,
} from '../../reports/report-dashboard/folders'
import { ReportTilesSkeleton } from '../../reports/report-dashboard/report-tile-skeletons'

export function ReportGroupView() {
  const routeParams = useParams<any>()
  const reportGroupId = routeParams.id
  const { data: license, isLoading: licenseLoading } = useGetLicense()
  const { data: homepageCards, isLoading: homepageLoading } = useGetDashboard()
  const { data: reports, isLoading: membersLoading } =
    useGetReportGroupMembers(reportGroupId)

  const { data: folders, isLoading: foldersLoading } =
    useGetFolders(reportGroupId)

  const { data: userFolders, isLoading: userFoldersLoading } = useGetFolders()

  const [selectedReportGroup, setSelectedReportGroup] = useState<any>(null)

  const foldersWithItems = folders?.map(folder => ({
    ...folder,
    items: folder.items?.map(item => ({
      ...item,
      accessDenied: !reports?.find(
        r => r.id === item.id || r.id === item.reportId
      ),
    })),
    disableEdit: true,
  }))

  const [skeletonNumber, setSkeletonNumber] = useState(0)
  const [foldersSkeletonNumber, setFoldersSkeletonNumber] = useState(0)

  useEffect(() => {
    const matchingReportGroup =
      homepageCards?.reportGroups?.find(group => group.id === reportGroupId) ||
      null

    if (!matchingReportGroup) return
    setSelectedReportGroup(matchingReportGroup)
  }, [homepageCards?.reportGroups, reportGroupId])

  // Check License validity
  const validLicense = getLicenseState(license).valid

  useEffect(() => {
    //Check if we have the number of reports in local storage
    const reportsInLocalStorage = localStorage.getItem(
      'reportGroupMembers' + reportGroupId
    )
    if (reportsInLocalStorage) {
      setSkeletonNumber(parseInt(reportsInLocalStorage))
    }

    const foldersInLocalStorage = localStorage.getItem(
      'folders' + reportGroupId
    )
    if (foldersInLocalStorage) {
      setFoldersSkeletonNumber(parseInt(foldersInLocalStorage))
    }

    const selectedReportGroupInLocalStorage = localStorage.getItem(
      'reportGroup' + reportGroupId
    )
    if (!selectedReportGroupInLocalStorage) return

    const parsedReportGroup = JSON.parse(selectedReportGroupInLocalStorage)
    setSelectedReportGroup(parsedReportGroup)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    reports &&
      localStorage.setItem(
        'reportGroupMembers' + reportGroupId,
        JSON.stringify(reports?.filter(r => r.isKeyReport)?.length)
      )

    folders &&
      localStorage.setItem(
        'folders' + reportGroupId,
        JSON.stringify(folders?.length)
      )

    selectedReportGroup &&
      reportGroupId &&
      localStorage.setItem(
        'reportGroup' + reportGroupId,
        JSON.stringify(selectedReportGroup)
      )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reports, folders])

  return (
    <Layout
      title={`${selectedReportGroup?.name.toUpperCase()} ANALYTICS`}
      loading={licenseLoading}
      error={
        (!selectedReportGroup &&
          !homepageLoading &&
          !licenseLoading &&
          !membersLoading) ||
        !validLicense
      }
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'baseline',
        justifyContent: 'center',
        flexGrow: 1,
        width: '100%',
        minWidth: theme => theme.spacing(48),
      }}
      errorMessage={
        'Error: You do not have access to this report group or the report group does not exist'
      }
    >
      <Stack
        sx={{
          m: 'auto',
          mb: 8,
          width: '100vw',
          maxWidth: theme => theme.spacing(151.5),
          minWidth: theme => theme.spacing(48),
          px: 4.8,
        }}
        gap={4}
        alignItems='flex-start'
      >
        {membersLoading || homepageLoading ? (
          <ReportTilesSkeleton
            numReports={skeletonNumber}
            title={'Dashboards'}
            subtitle={`${selectedReportGroup?.description} - Permission: ${selectedReportGroup?.permissionTitle}`}
          />
        ) : (
          <>
            {reports?.length === 0 && (
              <Typography sx={{ mt: 5, fontWeight: 600 }} variant='h3'>
                No reports to display
              </Typography>
            )}

            {reports?.length > 0 && !homepageLoading && selectedReportGroup && (
              <ReportTiles
                title={'Dashboards'}
                subtitle={`${selectedReportGroup?.description} - Permission: ${selectedReportGroup.permissionTitle}`}
                isLoading={membersLoading}
                cards={reports.filter(report => !!report.isKeyReport)}
                isFavoritesLoading={foldersLoading || userFoldersLoading}
                folders={folders}
                favoritesFolder={userFolders?.find(
                  folder => folder?.isFavorites
                )}
              />
            )}
          </>
        )}
        {foldersLoading || membersLoading ? (
          <FolderSkeletons numFolders={foldersSkeletonNumber} reportGroup />
        ) : (
          <Stack sx={{ width: '100%' }}>
            <Typography variant='h5' sx={{ fontWeight: 600, display: 'block' }}>
              {foldersWithItems.length
                ? `${selectedReportGroup?.name.toUpperCase()} Reports`
                : null}
            </Typography>
            {foldersWithItems.length ? (
              <Divider
                sx={{
                  mb: 0,
                }}
              />
            ) : null}

            <Folders
              folders={foldersWithItems}
              userFolders={userFolders}
              reportGroup
            />
          </Stack>
        )}
      </Stack>
    </Layout>
  )
}
