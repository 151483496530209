import { useEffect, useState, useRef, useMemo } from 'react'
import { PowerBIEmbed } from 'powerbi-client-react'
import {
  IDashboardEmbedConfiguration,
  IQnaEmbedConfiguration,
  IReportCreateConfiguration,
  IReportEmbedConfiguration,
  ITileEmbedConfiguration,
  IVisualEmbedConfiguration,
  models,
  Report,
} from 'powerbi-client'
import { EmbedAccess, WorkspaceInfo } from '../../../models'
import { powerBiApi } from '../../../api-interface'
import { FullPageLoader } from '../../../components'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { Box, CircularProgress, debounce } from '@mui/material'
import { reportStateAtom, bookmarkAtom, licenseAtom } from '../../../state'

export function PbiReport(props: PbiReportProps) {
  const {
    className,
    reportId,
    reportEmbedSettings,
    editMode,
    setShowCopyDialog,
    setHasRendered,
    handleSave,
  } = props

  const reportRef = useRef<Report>()
  const [embedAccess, setEmbedAccess] = useState<EmbedAccess>(null)
  const [errorMessage, setErrorMessage] = useState('')
  const [resettingFilters, setResettingFilters] = useState(false)
  const setReportState = useSetRecoilState(reportStateAtom)
  const [bookmarkState, setBookmarkState] = useRecoilState(bookmarkAtom)
  const licenseId = useRecoilValue(licenseAtom)
  const isFirstRender = useRef(true)
  const justAppliedBookmark = useRef(false)
  const numOfRenders = useRef(0)
  const [buffer, setBuffer] = useState<boolean>(false)

  useEffect(() => {
    debounceSetBuffer(true) // Set buffer to true immediately

    const timeoutId = setTimeout(() => {
      debounceSetBuffer(false) // Turn off buffer after 2 seconds
    }, 500)

    return () => {
      clearTimeout(timeoutId) // Clear timeout if component unmounts before 2 seconds
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editMode])

  const debounceSetBuffer = debounce((value: boolean) => {
    setBuffer(value)
  }, 200)

  useEffect(() => {
    async function fetchEmbedAccess() {
      try {
        const embedAccess = await powerBiApi.embedAccess({
          reportId: reportId,
          licenseId: licenseId,
        })
        setEmbedAccess(embedAccess)
      } catch (e) {
        let message = e.message
        if (e.message === 'Report Access Error') {
          message =
            'There was an issue authenticating access for this Report. If this persists, try updating the App Registration associated with this report.'
        }
        setErrorMessage(message)
        return
      }
    }
    fetchEmbedAccess()
  }, [reportId, licenseId])

  useEffect(() => {
    if (!reportRef?.current) return
    const newSettings = {
      panes: {
        filters: {
          visible: !!reportEmbedSettings.filterPaneEnabled,
        },
        pageNavigation: {
          visible: !!reportEmbedSettings.showPageNavigation,
        },
      },
    }
    reportRef.current.updateSettings(newSettings)
    if (reportEmbedSettings.defaultPageName) {
      reportRef.current.setPage(reportEmbedSettings.defaultPageName)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportEmbedSettings])

  useEffect(() => {
    if (!reportRef.current) return
    else if (editMode) reportRef.current.switchMode('edit')
    else reportRef.current.switchMode('view')
  }, [editMode])

  const getCurrentState = async () => {
    const currentReportState = (
      await reportRef.current.bookmarksManager.capture()
    ).state

    return btoa(currentReportState)
  }

  const captureReportState = async () => {
    if (!reportRef.current) return
    numOfRenders.current++

    isFirstRender.current = false

    const base64ReportState = await getCurrentState()
    setReportState(base64ReportState)

    if (hasBookmarkBeenApplied()) {
      justAppliedBookmark.current = false
      return
    }

    resetSelectedBookmark()
  }

  const hasBookmarkBeenApplied = () =>
    justAppliedBookmark.current || numOfRenders.current < 3

  const applyBookmarkState = async () => {
    if (!bookmarkState.selectedBookmark?.state || !reportRef.current) return
    justAppliedBookmark.current = true
    await reportRef.current.bookmarksManager.applyState(
      atob(bookmarkState.selectedBookmark.state)
    )
  }

  useEffect(() => {
    applyBookmarkState()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    bookmarkState.selectedBookmark?.state,
    reportEmbedSettings,
    reportRef.current,
  ])

  const resetSelectedBookmark = () => {
    setBookmarkState(prev => ({
      ...prev,
      selectedBookmark: null,
    }))

    //remove bookmark from url
    //<url>/<reportId>/<bookmarkId>
    const url = window.location.href
    const urlParts = url.split('/')
    if (urlParts.length > 5) {
      window.history.pushState({}, '', urlParts.slice(0, 5).join('/'))
    }
  }

  useEffect(() => {
    const resetReportFilters = async reportEmbedSettings => {
      setResettingFilters(true)
      await reportRef.current.reload()

      setBookmarkState(prev => ({
        ...prev,
        resetReportFilters: false,
      }))

      setTimeout(() => {
        setResettingFilters(false)
      }, 1000)
      const newSettings = {
        panes: {
          filters: {
            visible: !!reportEmbedSettings.filterPaneEnabled,
          },
          pageNavigation: {
            visible: !!reportEmbedSettings.showPageNavigation,
          },
        },
      }
      await reportRef.current.updateSettings(newSettings)
    }

    if (!bookmarkState.resetReportFilters || !reportRef.current) return

    resetReportFilters(reportEmbedSettings)
  }, [bookmarkState.resetReportFilters, setBookmarkState, reportEmbedSettings])

  const embedConfig:
    | IReportEmbedConfiguration
    | IDashboardEmbedConfiguration
    | ITileEmbedConfiguration
    | IQnaEmbedConfiguration
    | IVisualEmbedConfiguration
    | IReportCreateConfiguration = useMemo(
    () => ({
      type: 'report',
      id: embedAccess?.id,
      embedUrl: embedAccess?.embedUrl,
      accessToken: embedAccess?.accessToken,
      tokenType: models.TokenType.Embed,
      pageName: reportEmbedSettings.defaultPageName || undefined,
      permissions: models.Permissions.All,
      settings: {
        useCustomSaveAsDialog: true,
        panes: {
          filters: {
            visible: !!reportEmbedSettings.filterPaneEnabled,
          },
          pageNavigation: {
            visible: !!reportEmbedSettings.showPageNavigation,
          },
        },
      },
    }),
    [embedAccess, reportEmbedSettings]
  )

  const eventHandlers = new Map([
    [
      'rendered',
      async event => {
        setHasRendered(true)
        await captureReportState()
      },
    ],
    ['error', event => console.error(event.detail)],
    [
      'loaded',
      async event => {
        await applyBookmarkState()
      },
    ],
    [
      'saveAsTriggered',
      async event => {
        setShowCopyDialog(true)
      },
    ],
    [
      'saved',
      async (event: SaveEvent) => {
        handleSave()
      },
    ],
  ])

  if (!embedAccess) {
    return (
      <div className={className}>
        {errorMessage ? errorMessage : <FullPageLoader />}
      </div>
    )
  }

  const Loader = () => (
    <Box
      sx={{
        backgroundColor: 'rgba(0,0,0, 0.6)',
        width: '100%',
        height: '100%',
        position: 'absolute',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <CircularProgress
        sx={{
          position: 'absolute',
          top: '30%',
          left: '50%',
          transform: 'translate(-50%, -50%) rotate(180deg)',
          background: 'transparent',
          color: 'white',
        }}
        size={60}
      />
    </Box>
  )

  return (
    <Box
      sx={{
        height: '100%',
        width: '100%',
        display: 'flex',
        position: 'relative',
        opacity: buffer ? 0.5 : 1,
        transition: 'width 4s height 4s',
      }}
    >
      {(resettingFilters || buffer) && <Loader />}
      <PowerBIEmbed
        embedConfig={embedConfig}
        eventHandlers={eventHandlers}
        cssClassName={className}
        getEmbeddedComponent={embeddedReport => {
          reportRef.current = embeddedReport as Report
        }}
      />
    </Box>
  )
}

export type PbiReportProps = {
  reportId: string
  reportEmbedSettings: {
    filterPaneEnabled: boolean
    showPageNavigation: boolean
    defaultPageName: string
  }
  setHasRendered: (state: boolean) => void
  className?: string
  requiresRls?: boolean
  embedType?: 'report' | 'dashboard' | 'tile' | 'visual' | 'qna'
  editMode: boolean
  setShowCopyDialog: (state: boolean) => void
  copyReport?: {
    name: string
    description: string
    workspace: WorkspaceInfo
    shareWithLicense: boolean
  }
  isResizing?: boolean
  handleSave: () => void
}

export type SaveEvent = {
  error?: string
  originalReportObjectId?: string
  reportName?: string
  reportObjectId: string
  saveAs: boolean
}
