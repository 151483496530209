import { Stack, Typography, Divider, Button } from '@mui/material'
import { Folder } from '../../../../models'
import { SingleFolder } from './folder'
import ManageFolderDialog from '../manage-folder-dialog'
import { useState, useEffect } from 'react'
import { useDeleteFolder } from '../../../../hooks/mutations/useDeleteFolder'
import { useCreateFolder, useUpdateFolder } from '../../../../hooks'
import { ToastNotification } from '../../../../components/common/Toast'

function rearrangeArray(items, folderName) {
  if (!items) return []
  const folderIndex = items.findIndex(item => item.name === folderName)

  const folder = folderIndex !== -1 ? items.splice(folderIndex, 1)[0] : null

  if (folder) items.unshift(folder)

  return items
}

type FoldersProps = {
  folders: Folder[]
  userFolders?: Folder[]
  reportGroup?: boolean
}

export function Folders(props: FoldersProps) {
  const { folders, userFolders, reportGroup } = props
  const [openFolderSettings, setOpenFolderSettings] = useState(false)
  const [otherFoldersWithFavoritesProp, setOtherFoldersWithFavoritesProp] =
    useState<Folder[]>([])

  const [toastOpen, setToastOpen] = useState(false)
  const [toastMessage, setToastMessage] = useState('')
  const [toastVariant, setToastVariant] = useState<
    'primary' | 'success' | 'warning' | 'error'
  >('success')

  const {
    mutateAsync: updateFolder,
    isLoading: isUpdatingFolder,
    isError: isUpdateError,
    isSuccess: isUpdateSuccess,
  } = useUpdateFolder()
  const {
    mutateAsync: createFolder,
    isLoading: isCreatingFolder,
    isError: isCreateError,
    isSuccess: isCreateSuccess,
  } = useCreateFolder()
  const {
    mutateAsync: deleteFolder,
    isLoading: isDeletingFolder,
    isError: isDeleteError,
    isSuccess: isDeleteSuccess,
  } = useDeleteFolder()

  useEffect(() => {
    const isError = isUpdateError || isCreateError || isDeleteError
    const isSuccess = isUpdateSuccess || isCreateSuccess || isDeleteSuccess
    const updateComplete = isUpdateSuccess || isUpdateError
    const createComplete = isCreateSuccess || isCreateError
    const categoryMessage = updateComplete
      ? 'updating'
      : createComplete
      ? 'creating'
      : 'deleting'
    if (isError) {
      setToastVariant('error')
      setToastMessage(`Error ${categoryMessage} folder`)
      setToastOpen(true)
    }
    if (isSuccess) {
      setToastVariant('success')
      setToastMessage(`Success ${categoryMessage} folder`)
      setToastOpen(true)
    }
  }, [
    isUpdateError,
    isCreateError,
    isDeleteError,
    isUpdateSuccess,
    isCreateSuccess,
    isDeleteSuccess,
  ])

  const favoriteFolder =
    folders?.filter(folder => folder.isFavorites)[0] ||
    userFolders?.filter(folder => folder.isFavorites)[0]

  useEffect(() => {
    const otherFolders = folders?.filter(folder => !folder.isFavorites)
    if (!favoriteFolder) {
      setOtherFoldersWithFavoritesProp(otherFolders)
      return
    }

    const foldersWithFavoritesProp = folders?.map(curFolder => {
      // For Favorites folder replaced line under
      // otherFolders?.map(curFolder => {
      return {
        ...curFolder,
        items: curFolder?.items?.map(item => {
          return {
            ...item,
            isFavorite: favoriteFolder?.items?.some(
              favoriteItem => favoriteItem.id === item.id
            ),
          }
        }),
      }
    })

    const foldersWithSortedItems = foldersWithFavoritesProp?.map(folder => {
      if (!folder.disableEdit) {
        return folder
      }

      return {
        ...folder,
        items: folder?.items?.sort((a, b) => {
          if (a.isFavorite && !b.isFavorite) {
            return -1
          }
          if (!a.isFavorite && b.isFavorite) {
            return 1
          }
          return 0
        }),
      }
    })
    setOtherFoldersWithFavoritesProp(foldersWithSortedItems)
  }, [favoriteFolder, folders])

  rearrangeArray(otherFoldersWithFavoritesProp, 'My Reports')
  rearrangeArray(otherFoldersWithFavoritesProp, 'Favorites')

  return (
    <Stack alignItems='baseline' gap={0} sx={{ mb: 0, width: '100%' }}>
      {!reportGroup && (
        <Stack direction='column' sx={{ width: '100%', overflow: 'hidden' }}>
          <Stack
            direction='row'
            alignItems='baseline'
            gap={1}
            sx={{ height: '25px', mb: 0.5 }}
          >
            <Typography variant='h5' sx={{ fontWeight: 600, display: 'block' }}>
              {'My Folders'}
            </Typography>
            <Button
              variant='text'
              sx={{
                color: theme => theme.palette.text.secondary,
                '&:hover': {
                  backgroundColor: 'transparent',
                },
              }}
              onClick={() => setOpenFolderSettings(true)}
            >
              Add New Folder
            </Button>
          </Stack>
          <Divider sx={{ width: '100%' }} />
        </Stack>
      )}
      <Stack
        direction='row'
        gap={1}
        sx={{
          whiteSpace: 'wrap',
          flexWrap: 'wrap',
          overflow: 'auto',
          width: '100%',
          mt: 1,
        }}
      >
        {otherFoldersWithFavoritesProp?.map((folder, index) => (
          <SingleFolder
            key={folder.id + index}
            folder={folder}
            numFolders={folders?.length}
            userFolders={userFolders ? userFolders : folders}
            createFolder={createFolder}
            updateFolder={updateFolder}
            deleteFolder={deleteFolder}
            isLoading={isCreatingFolder || isUpdatingFolder || isDeletingFolder}
          />
        ))}
        {openFolderSettings && (
          <ManageFolderDialog
            open={openFolderSettings}
            onClose={() => setOpenFolderSettings(false)}
            createFolder={createFolder}
            updateFolder={updateFolder}
            deleteFolder={deleteFolder}
            isLoading={isCreatingFolder || isUpdatingFolder || isDeletingFolder}
          />
        )}
      </Stack>
      <ToastNotification
        open={toastOpen}
        onClose={() => setToastOpen(false)}
        message={toastMessage}
        variant={toastVariant}
        autoTimeout={2000}
      />
    </Stack>
  )
}
