import React, { useState, useRef } from 'react'
import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  Crop,
  PixelCrop,
} from 'react-image-crop'
import { canvasPreview } from './canvasPreview'
import { useDebounceEffect } from './useDebounceEffect'

import 'react-image-crop/dist/ReactCrop.css'
import { Button, Theme, alpha, Typography } from '@mui/material'
import { makeStyles, createStyles } from '@mui/styles'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    addLogoBox: {
      height: '200px',
      width: '300px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: alpha(theme.palette.primary.primaryColor400, 0.7),
      '&:hover': {
        backgroundColor: alpha(theme.palette.primary.primaryColor400, 0.3),
      },
    },
    secondaryColor: {
      color: theme.palette.primary.primaryColor400,
    },
    imagePreviewer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  })
)

type ImageEditorProps = {
  label: string
  handleLogoChange: (logo: HTMLCanvasElement, image: HTMLImageElement) => void
  backgroundColor: string
  onClose: () => void
  isLogo?: boolean
  preview: {
    height: string
    width: string
    borderRadius?: string
    border?: string
  }
  aspect?: number
}

export function ImageEditor(props: ImageEditorProps) {
  const classes = useStyles()
  const [imgSrc, setImgSrc] = useState('')
  const previewCanvasRef = useRef<HTMLCanvasElement>(null)
  const imgRef = useRef<HTMLImageElement>(null)
  const [crop, setCrop] = useState<Crop>()
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>()

  const {
    label,
    handleLogoChange,
    backgroundColor,
    preview,
    aspect,
    onClose,
    isLogo,
  } = props

  function onSelectFile(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.files && e.target.files.length > 0) {
      setCrop(undefined) // Makes crop preview update between images.
      const reader = new FileReader()
      reader.addEventListener('load', () => {
        setImgSrc(reader.result?.toString() || '')
      })
      reader.readAsDataURL(e.target.files[0])
    }
  }

  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
    const { width, height } = e.currentTarget

    setCrop(centerAspectCrop(width, height, aspect || 16 / 9))
    setCompletedCrop({
      unit: 'px',
      x: 0,
      y: 0,
      width: width > height ? height : width,
      height: width > height ? height : width,
    })
  }
  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(imgRef.current, previewCanvasRef.current, completedCrop)
      }
    },
    100,
    [completedCrop]
  )

  // const handleAspectChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const checked = e.target.checked
  //   let newWidth = checked ? bannerAspect1 * 100 : bannerAspect2 * 100
  //   if (newWidth > 290) {
  //     // Max width of the image previewer.
  //     newWidth = 290
  //   }

  //   const newCrop: Crop = {
  //     x: 5,
  //     y: 5,
  //     width: newWidth,
  //     height: 100,
  //     unit: 'px',
  //   }

  //   if (e.target.checked) {
  //     setAspect(bannerAspect1)
  //   } else {
  //     setAspect(bannerAspect2)
  //   }
  //   setCrop(newCrop)
  // }
  if (!crop) {
    setCrop({
      unit: '%',
      width: 90,
      height: 90,
      x: 5,
      y: 5,
    })
  }

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        height: '100%',
        width: 'auto',
        flexDirection: 'column',
        padding: '10px',
      }}
    >
      {!imgSrc && (
        <>
          <Button className={classes.addLogoBox} component='label'>
            <Typography style={{ color: backgroundColor }} fontSize={45}>
              +
            </Typography>
            <input
              type='file'
              onChange={event => {
                onSelectFile(event)
              }}
              hidden
              accept='image/png, image/svg+xml, image/svg'
            />
          </Button>
          <Typography variant='body2' style={{ marginTop: '5px' }}>
            File must be a PNG, or SVG format.
          </Typography>
          <Typography
            variant='body2'
            style={{ textWrap: 'wrap', maxWidth: '300px' }}
          >
            {isLogo
              ? 'Recommended height size 60px'
              : 'Recommended size 130px x 130px'}
          </Typography>
          <Button
            sx={{ marginTop: '5px', width: '65px', alignSelf: 'end' }}
            className={'button secondaryButton'}
            onClick={onClose}
          >
            Cancel
          </Button>
        </>
      )}
      <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            height: 'auto',
            width: '300px',
          }}
        >
          {!!imgSrc && (
            <ReactCrop
              crop={crop}
              onChange={(_, percentCrop) => setCrop(percentCrop)}
              onComplete={c => {
                setCompletedCrop(c)
              }}
              aspect={aspect}
            >
              <img ref={imgRef} alt='Logo' src={imgSrc} onLoad={onImageLoad} />
            </ReactCrop>
          )}
        </div>
        {!!completedCrop && imgSrc && (
          <div
            style={{
              display: 'block',
              backgroundColor: backgroundColor,
            }}
            className={classes.imagePreviewer}
          >
            <canvas
              ref={previewCanvasRef}
              style={{
                objectFit: 'contain',
                width: '65px',
                borderRadius: preview.borderRadius,
                borderWidth: '1px',
                borderStyle: 'solid',
              }}
            />
            <Typography>Preview of {label}</Typography>
          </div>
        )}
      </div>

      {imgSrc && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'end',
            gap: 10,
            marginTop: 5,
          }}
        >
          <Button className={'button secondaryButton'} onClick={onClose}>
            Cancel
          </Button>
          <Button
            className={'button primaryButton'}
            onClick={() => {
              handleLogoChange(previewCanvasRef.current, imgRef.current)
            }}
          >
            {`Save ${label}`}
          </Button>
        </div>
      )}
    </div>
  )
}

// This is to demonstate how to make and center a % aspect crop
// which is a bit trickier so we use some helper functions.
export function centerAspectCrop(
  mediaWidth: number,
  mediaHeight: number,
  aspect: number
) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  )
}
