import { getAxios, endpoints } from './util'
import { EmbedAccess } from '../models'

export const powerBiApi = {
  async embedAccess({
    reportId,
    licenseId,
  }: {
    reportId: string
    licenseId: string
  }): Promise<EmbedAccess> {
    let axios = await getAxios()
    let response
    try {
      response = await axios({
        method: 'post',
        url: `${endpoints.embedAccess}`,
        data: { reportId, licenseId },
      })
    } catch (e) {
      response = e.response
      throw new Error(response.data.errorMessage)
    }
    return response.data.payload as EmbedAccess
  },
}
