import {
  Box,
  CircularProgress,
  Divider,
  Icon,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Popover,
  Stack,
  TextField,
  Theme,
  Typography,
  alpha,
  Button,
} from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
import cs from 'classnames'
import { Bookmark, Folder, NewBookmark } from '../../../models'
import {
  useAddItemsToFavorites,
  useDeleteBookmark,
  useGetBookmarkMember,
  useGetFolders,
  useRemoveItemFromFavorites,
  useUpdateBookmark,
} from '../../../hooks'
import { bookmarkAtom } from '../../../state/bookmarkAtom'
import { useRecoilState, useRecoilValue } from 'recoil'
import { BookmarksSkeleton } from './bookmarks-skeleton'
import { useEffect, useRef, useState } from 'react'
import { OverflowMenu } from '../../admin/shared/existing-items/overflowMenu'
import { reportStateAtom } from '../../../state'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { useUpdateFolder } from '../../../hooks/mutations/useUpdateFolder'
import { useCreateFolder } from '../../../hooks/mutations/useCreateFolder'
import { useParams } from 'react-router-dom'
import { Star, StarBorder } from '@mui/icons-material'
import GroupsIcon from '@mui/icons-material/Groups'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      width: '100%',
      overflowY: 'auto',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    bookmarkSelector: {
      minWidth: '30px',
      paddingTop: 0,
      paddingBottom: 0,
      '&:hover': {
        backgroundColor: theme.palette.primary.primaryColor100,
      },
      borderBottom: '1px solid #F2F2F2',
      position: 'relative',
      alignItems: 'center',
      paddingLeft: theme.spacing(0.5),
      paddingRight: theme.spacing(0.5),
      cursor: 'pointer',
      overflowWrap: 'break-word',
    },
    bookmarkSelected: {
      backgroundColor: theme.palette.primary.primaryColor400,
      '&:hover': {
        backgroundColor: theme.palette.primary.primaryColor400,
      },
      color: '#fff',
    },
    bookmarkSelectorIcon: {
      marginRight: theme.spacing(1),
      minWidth: 0,
    },
    icon: {
      zIndex: 100,
      color: alpha(theme.palette.primary.primaryColor400, 0.7),
      '&:hover': {
        color: theme.palette.primary.primaryColor400,
      },
    },
    iconSelected: {
      color: alpha('#fff', 0.3),
      '&:hover': {
        color: '#fff',
      },
    },
    selected: {
      color: '#fff',
    },
    muiBookmarkSelectorIcon: {
      marginRight: theme.spacing(0),
      width: '16px',
      height: '16px',
    },
    bookmarkTitle: {
      height: '30px',
      fontWeight: 600,
      //borderTop: '1px solid #F2F2F2',
      borderBottom: '1px solid #F2F2F2',
      textAlign: 'center',
      lineHeight: '15px',
      padding: '7.5px 0',
    },
    sharedIcon: {
      minWidth: '16px',
      '& .MuiSvgIcon-root': {
        fontSize: '16px',
      },
      padding: 4,
    },
    button: {
      width: '100%',
      //borderTop: '1px solid #F2F2F2',
      borderBottom: '1px solid #F2F2F2',
      borderRadius: 0,
      color: theme.palette.text.secondary,
      '&:hover': {
        backgroundColor: '#9DD5FF',
      },
    },
  })
)

type BookmarkWithFavorite = Bookmark & { isFavorite?: boolean }

export function BookmarkSelector(props: BookmarkSelectorProps) {
  const {
    bookmarks,
    bookmarksLoading,
    licenseId,
    reportId,
    onAddClick,
    onEdit,
    drawerWidth,
  } = props
  const classes = useStyles()

  const [bookmarkState, setBookmarkState] = useRecoilState(bookmarkAtom)
  const reportState = useRecoilValue(reportStateAtom)

  const { mutateAsync: deleteBookmark, isLoading: isDeleting } =
    useDeleteBookmark(reportId)
  const { mutateAsync: updateBookmark, isLoading: isUpdating } =
    useUpdateBookmark(reportId)
  const { data: folders, isLoading: foldersLoading } = useGetFolders()
  const [numSkeletons, setNumSkeletons] = useState(0)

  const { mutateAsync: updateFolder } = useUpdateFolder()
  const { mutateAsync: createFolder } = useCreateFolder()

  const { licenseBookmarks, ownedBookmarks, sharedBookmarks } = bookmarks

  const [allBookmarks, setAllBookmarks] = useState<BookmarkWithFavorite[]>([])
  const favoritesFolder = folders?.find(folder => folder?.isFavorites)

  useEffect(() => {
    setAllBookmarks(
      [...licenseBookmarks, ...ownedBookmarks, ...sharedBookmarks]?.filter(
        (bookmark, index, self) => {
          return index === self.findIndex(t => t.id === bookmark.id)
        }
      ) || []
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookmarks])

  useEffect(() => {
    //Check if we have the number of reports in local storage
    const bookmarks = localStorage.getItem('bookmarks' + reportId)
    setNumSkeletons(bookmarks ? parseInt(bookmarks) : 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (bookmarksLoading) return
    localStorage.setItem(
      'bookmarks' + reportId,
      JSON.stringify(allBookmarks?.length || 0)
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookmarks, bookmarksLoading, reportId])

  const allSharedBookmarks =
    [...licenseBookmarks, ...sharedBookmarks]
      ?.filter((bookmark, index, self) => {
        return index === self.findIndex(t => t.id === bookmark.id)
      })
      ?.map(bookmark => {
        return {
          ...bookmark,
          isFavorite: favoritesFolder?.items?.some(
            favoriteItem => favoriteItem.id === bookmark.id
          ),
        }
      }) || []

  const setActiveBookmark = (bookmark: Bookmark) => {
    setBookmarkState({
      ...bookmarkState,
      selectedBookmark:
        bookmark === bookmarkState.selectedBookmark
          ? bookmarkState.selectedBookmark
          : bookmark,
      panelOpen: true,
    })
  }

  const handleBookmarksClick = () => {
    setBookmarkState({
      ...bookmarkState,
      panelOpen: !bookmarkState.panelOpen,
    })
  }

  async function handleUpdate(bookmark) {
    let newBookmark: NewBookmark = {
      id: bookmark.id,
      name: bookmark.name,
      state: reportState,
      description: bookmark.description,
      icon: bookmark.icon,
      reportId: reportId,
      licenseNodeId: licenseId,
    }

    await updateBookmark(newBookmark)
  }

  const handleAddBookmark = () => {
    onAddClick()
  }

  const removeBookmarkId = () => {
    window.history.replaceState({}, '', `/reports/${reportId}`)
  }

  const handleAddToFolder = async (bookmark: Bookmark, folder: Folder) => {
    if (folder?.id === '' && folder?.name === '') {
      return
    }

    if (folder?.name && folder?.id === '') {
      await createFolder({
        folderName: folder.name,
        items: [
          {
            id: bookmark.id,
            type: 'bookmark',
            name: bookmark.name?.replaceAll(/'/g, ''),
            description: bookmark.description,
            reportId: reportId,
          },
        ],
      })
    }

    if (!folder.id) return

    await updateFolder({
      folderId: folder.id,
      folderName: folder.name,
      items: [
        ...folder?.items,
        {
          id: bookmark.id,
          type: 'bookmark',
          name: bookmark.name?.replaceAll(/'/g, ''),
          description: bookmark.description,
          reportId: reportId,
        },
      ],
    })
  }

  const handleBookmarkDelete = (bookmarkId: string) => {
    deleteBookmark(bookmarkId)
    if (bookmarkState.selectedBookmark?.id === bookmarkId) {
      removeBookmarkId()
    }
  }

  if (bookmarksLoading || isDeleting || isUpdating) {
    return <BookmarksSkeleton numberOfBookmarks={numSkeletons} />
  }

  return (
    <List className={classes.drawer}>
      {/* {hasUnsavedChanges && (
        <Typography sx={{color: 'red', marginLeft: '12px'}} fontStyle='italic'>
          There are unsaved changes to the current report
        </Typography>
      )} */}
      <HideBookmarks onClick={handleBookmarksClick} />
      <AddBookmark key={allBookmarks.length + 1} onClick={handleAddBookmark} />

      {allSharedBookmarks.length > 0 && (
        <Stack direction='column'>
          <Typography variant='overline' className={classes.bookmarkTitle}>
            Shared Bookmarks
          </Typography>
          {allSharedBookmarks.map(
            (bookmark: BookmarkWithFavorite, index: number) => {
              return (
                <BookmarkButton
                  key={index}
                  isSelected={
                    bookmark.id === bookmarkState.selectedBookmark?.id
                  }
                  isOwner={false}
                  onClick={() => {
                    setActiveBookmark(bookmark)
                  }}
                  onEdit={onEdit}
                  onDelete={handleBookmarkDelete}
                  onUpdate={e => {
                    handleUpdate(bookmark)
                  }}
                  bookmark={bookmark}
                  drawerWidth={drawerWidth || 280}
                  onAddToFolder={(bookmark: Bookmark, folder: Folder) => {
                    handleAddToFolder(bookmark, folder)
                  }}
                  foldersLoading={foldersLoading}
                >
                  {bookmark.name}
                </BookmarkButton>
              )
            }
          )}
        </Stack>
      )}

      {ownedBookmarks.length > 0 && (
        <Stack direction='column' alignContent='center' sx={{ width: '100%' }}>
          <Typography variant='inherit' className={classes.bookmarkTitle}>
            Owned Bookmarks
          </Typography>
          {ownedBookmarks
            ?.map(bookmark => {
              return {
                ...bookmark,
                isFavorite: favoritesFolder?.items?.some(
                  favoriteItem => favoriteItem.id === bookmark.id
                ),
              }
            })
            ?.map((bookmark: BookmarkWithFavorite, index: number) => {
              return (
                <BookmarkButton
                  key={index}
                  isSelected={
                    bookmark.id === bookmarkState.selectedBookmark?.id
                  }
                  isOwner={true}
                  onClick={() => {
                    setActiveBookmark(bookmark)
                  }}
                  onEdit={onEdit}
                  onDelete={handleBookmarkDelete}
                  onUpdate={e => {
                    handleUpdate(bookmark)
                  }}
                  bookmark={bookmark}
                  drawerWidth={drawerWidth || 280}
                  onAddToFolder={(bookmark: Bookmark, folder: Folder) => {
                    handleAddToFolder(bookmark, folder)
                  }}
                  foldersLoading={foldersLoading}
                >
                  {bookmark.name}
                </BookmarkButton>
              )
            })}
        </Stack>
      )}
    </List>
  )
}

type AllBookmarks = {
  licenseBookmarks: Bookmark[]
  ownedBookmarks: Bookmark[]
  sharedBookmarks: Bookmark[]
}

export type BookmarkSelectorProps = {
  bookmarks: AllBookmarks
  bookmarksLoading: boolean
  reportId: string
  licenseId: string
  onAddClick: () => void
  onEdit: (bookmark: Bookmark) => void
  drawerWidth: number
}

export function BookmarkButton(props: BookmarkButtonProps) {
  const {
    isSelected,
    isOwner,
    onClick,
    onEdit,
    onDelete,
    onUpdate,
    onAddToFolder,
    children,
    foldersLoading,
    bookmark,
  } = props
  const classes = useStyles()

  const routeParams = useParams<any>()
  const reportId = routeParams.id

  let isShared = useGetBookmarkMember(bookmark?.id).data
  const { mutateAsync: addToFavorites, isLoading: isAddingToFavorites } =
    useAddItemsToFavorites()
  const {
    mutateAsync: removeFromFavorites,
    isLoading: isRemovingFromFavorites,
  } = useRemoveItemFromFavorites()

  const showFavorites = !!bookmark

  const [isFavoritesLoading, setIsFavoritesLoading] = useState(false)

  const [isHovered, setIsHovered] = useState(false)

  const [isOverflowOpen, setIsOverflowOpen] = useState(false)

  const ref = useRef(null)

  const handleMouseEnter = () => {
    setIsHovered(true)
  }

  const handleMouseLeave = () => {
    setIsHovered(false)
  }

  const handleClick = event => {
    event.preventDefault()
    onClick()
  }

  const handleEdit = () => {
    onEdit(bookmark)
  }

  const handleDelete = () => {
    onDelete(bookmark.id)
  }

  const handleUpdate = () => {
    onUpdate(bookmark)
  }

  const [anchorEl, setAnchorEl] = useState<React.RefObject<HTMLButtonElement>>()

  const [isFolderOpen, setIsFolderOpen] = useState(false)

  const handleFolderClick = (newRef: React.RefObject<HTMLButtonElement>) => {
    setAnchorEl(newRef)
  }
  const handleFolderClose = () => {
    setAnchorEl(null)
    setIsFolderOpen(false)
    setIsOverflowOpen(false)
  }

  const id = isFolderOpen ? 'simple-popover' : undefined

  // const icon = getIconForBookmark(bookmark)

  return (
    <>
      <Popover
        id={id}
        open={isFolderOpen}
        anchorEl={anchorEl?.current}
        onClose={handleFolderClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        slotProps={{
          paper: {},
        }}
      >
        <AddToFolderOptions
          bookmarkId={bookmark?.id}
          handleAddToFolder={(folder: Folder, newFolderName?: string) => {
            onAddToFolder && onAddToFolder(bookmark, folder, newFolderName)
            handleFolderClose()
          }}
          handleFolderClose={() => {
            handleFolderClose()
          }}
        />
      </Popover>
      <ListItem
        ref={ref}
        onClick={handleClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        className={cs(classes.bookmarkSelector, {
          [classes.bookmarkSelected]: isSelected,
          [classes.selected]: isSelected,
        })}
        sx={{
          display: 'flex',
          alignItems: 'baseline',
          justifyContent: 'space-between',
          height: 'fit-content',
        }}
      >
        {showFavorites && (
          <Box>
            {isAddingToFavorites ||
            isRemovingFromFavorites ||
            isFavoritesLoading ||
            foldersLoading ? (
              <Box
                sx={{
                  width: '30px',
                  ml: -0.5,
                  mb: 0.5,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <CircularProgress size={20} />
              </Box>
            ) : (
              <IconButton
                component='button'
                color='inherit'
                aria-label='menu'
                onClick={async e => {
                  e.stopPropagation()
                  setIsFavoritesLoading(true)
                  bookmark?.isFavorite
                    ? await removeFromFavorites({ itemId: bookmark?.id })
                    : await addToFavorites({
                        item: {
                          id: bookmark?.id,
                          type: 'bookmark',
                          name: bookmark?.name,
                          description: bookmark?.description,
                          reportId: reportId,
                        },
                      })

                  await setTimeout(() => {
                    setIsFavoritesLoading(false)
                  }, 650)
                }}
                size='small'
                sx={{
                  mb: 0.5,
                  ml: -0.5,
                  '&:hover': {
                    backgroundColor: 'transparent',
                    color: theme => theme.palette.primary.primaryColor400,
                  },
                }}
              >
                {bookmark?.isFavorite ? (
                  <Star
                    className={cs(classes.icon, {
                      [classes.iconSelected]: isSelected,
                    })}
                  />
                ) : (
                  <StarBorder
                    className={cs(classes.icon, {
                      [classes.iconSelected]: isSelected,
                    })}
                  />
                )}
              </IconButton>
            )}
          </Box>
        )}
        <ListItemText
          primary={children}
          primaryTypographyProps={{ variant: 'inherit' }}
          secondary={isShared ? <GroupsIcon /> : null}
          secondaryTypographyProps={{
            display: 'flex',
            alignItems: 'center',
            variant: 'inherit',
            color: isSelected
              ? alpha('#fff', 0.3)
              : theme => alpha(theme.palette.primary.primaryColor400, 0.7),
          }}
          sx={{
            display: 'flex',
            wordBreak: 'break-word',
            gap: '10px',
          }}
        />
        <Stack
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            minWidth: '30px',
          }}
        >
          {isShared && !(isHovered || isSelected)}
          {(isHovered || isSelected) && bookmark && (
            <OverflowMenu
              isOpen={isOverflowOpen}
              setIsOpen={setIsOverflowOpen}
              {...(isOwner && {
                onDelete: e => {
                  e.stopPropagation()
                  handleDelete()
                },
                onEdit: e => {
                  e.stopPropagation()
                  handleEdit()
                },
                onUpdate: e => {
                  e.stopPropagation()
                  handleUpdate()
                },
              })}
              onAddToFolderClicked={_e => {
                setIsOverflowOpen(false)
                setIsFolderOpen(true)
              }}
              setRef={handleFolderClick}
              editButtonName={'Edit'}
              deleteButtonName={'Delete'}
              sx={{
                padding: 4,
                color: isSelected ? '#fff' : '#000',
              }}
            />
          )}
        </Stack>
      </ListItem>
    </>
  )
}

type AddToFolderOptionsProps = {
  handleAddToFolder: (folder: Folder, newFolderName?: string) => void
  handleFolderClose: () => void
  bookmarkId: string
}

function AddToFolderOptions(props: AddToFolderOptionsProps) {
  const { handleAddToFolder, handleFolderClose, bookmarkId } = props
  const [isAddingFolder, setIsAddingFolder] = useState(false)
  const { data: folders } = useGetFolders()
  const [newFolderName, setNewFolderName] = useState('')

  const filteredFolders = folders?.filter(
    folder => !folder?.items?.some(item => item?.id === bookmarkId)
  )

  return (
    <Stack
      direction='column'
      justifyContent='center'
      alignItems='flex-start'
      spacing={1}
      sx={{ maxHeight: '280px', overflowY: 'auto', minWidth: '240px' }}
    >
      <Typography variant='body2' fontWeight='bold' sx={{ pl: 1 }}>
        Add to Folder
      </Typography>
      <Divider
        sx={{
          width: '100%',
        }}
      />
      <Stack
        onClick={() => {
          setIsAddingFolder(true)
        }}
        direction={'row'}
        justifyContent={'flex-start'}
        sx={{
          cursor: 'pointer',
          borderRadius: '8px',
          width: '100%',
          p: 1,
          '&:hover': {
            backgroundColor: theme =>
              alpha(theme.palette.primary.primaryColor400, 0.3),
          },
          border: theme =>
            !isAddingFolder
              ? `1px dashed ${theme.palette.primary.primaryColor400}`
              : 'none',
        }}
      >
        {isAddingFolder ? (
          <>
            <TextField
              autoFocus
              label='Folder Name'
              variant='standard'
              size='small'
              sx={{ width: '100%' }}
              value={newFolderName}
              onChange={e => setNewFolderName(e.target.value)}
              //if enter key is pressed, add folder
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  setIsAddingFolder(false)
                  handleAddToFolder(
                    {
                      id: '',
                      name: newFolderName,
                    } as Folder,
                    newFolderName
                  )
                  handleFolderClose()
                }
              }}
              InputProps={{
                endAdornment: (
                  <Typography
                    onClick={() => {
                      setIsAddingFolder(false)
                      handleAddToFolder(
                        {
                          id: '',
                          name: newFolderName,
                        } as Folder,
                        newFolderName
                      )
                      handleFolderClose()
                    }}
                    sx={{
                      fontSize: '14px',
                      mb: 1,
                      cursor: 'pointer',
                      color: theme => theme.palette.primary.primaryColor400,
                      '&:hover': { textDecoration: 'underline' },
                    }}
                  >
                    Confirm
                  </Typography>
                ),
              }}
            />
          </>
        ) : (
          <>
            <Typography
              fontStyle={'italic'}
              sx={{
                cursor: 'pointer',
              }}
            >
              Create New
            </Typography>
            <Icon>
              <AddOutlinedIcon />
            </Icon>
          </>
        )}
      </Stack>

      {filteredFolders?.map((folder, index) => (
        <Box
          key={index}
          onClick={() => {
            handleAddToFolder(folder)
            handleFolderClose()
          }}
          sx={{
            cursor: 'pointer',
            width: '100%',
            borderRadius: '8px',
            p: 1,
            '&:hover': {
              backgroundColor: theme =>
                alpha(theme.palette.primary.primaryColor400, 0.3),
            },
          }}
        >
          <Typography>{folder.name}</Typography>
        </Box>
      ))}
    </Stack>
  )
}

export type BookmarkButtonProps = {
  isSelected: boolean
  onClick: () => void
  isOwner: boolean
  onEdit?: (bookmark: Bookmark) => void
  onDelete?: (bookmarkId: string) => void
  onUpdate?: (bookmark: Bookmark) => void
  onAddToFolder?: (
    bookmark: Bookmark,
    folder: Folder,
    newFolderName?: string
  ) => void
  children: string
  drawerWidth?: number
  bookmark?: BookmarkWithFavorite
  foldersLoading?: boolean
}

export function AddBookmark(props: AddBookmarkProps) {
  const { onClick } = props
  const classes = useStyles()

  return (
    <Box flexDirection='column' sx={{ alignContent: 'center' }}>
      <Button className={`${classes.button}`} onClick={onClick}>
        Add New Bookmark
      </Button>
    </Box>
  )
}

export type AddBookmarkProps = {
  onClick: () => void
}

export function HideBookmarks({ onClick }) {
  const classes = useStyles()

  return (
    <Box flexDirection='column' sx={{ mt: '27px', alignContent: 'center' }}>
      <Button
        className={`${classes.button}`}
        sx={{ alignItems: 'center', justifyContent: 'end', height: '45px' }}
        onClick={onClick}
      >
        Hide Bookmarks
        <Icon>
          <ChevronRightIcon />
        </Icon>
      </Button>
    </Box>
  )
}
