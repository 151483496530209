import { makeStyles } from '@mui/styles'
import {
  Theme,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  CircularProgress,
  Stack,
} from '@mui/material'
import { useState } from 'react'
import { useSetPaypalSubscription, useGetLicense } from '../../../../hooks'
import { useCancelPaypalSubscription } from '../../../../hooks/mutations/useCancelSubscription'
import { useGetPaypalSubscriptionPlan } from '../../../../hooks/queries/useGetPaypalSubscriptionPlan'
import { Subscription } from './subscription'
import { SubscriptionPlan } from '../../../../models/paypal'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    height: '300px',
    marginTop: theme.spacing(4),
    padding: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
  keepSubscription: {
    color: theme.palette.primary.primaryColor400,
  },
}))

export function SubscriptionList() {
  const classes = useStyles()
  const { data: plans, isLoading: subscriptionsLoading } =
    useGetPaypalSubscriptionPlan()
  const { mutate: cancelSubscription } = useCancelPaypalSubscription()
  const { mutate: setPayPalSubscriptionStatus } = useSetPaypalSubscription()
  const {
    data: license,
    isFetched: isLicenseFetched,
    refetch: refreshLicense,
  } = useGetLicense()

  const [isStatusChangeDialogOpen, setIsStatusChangeDialogOpen] =
    useState(false)
  const [isCancelDialogOpen, setIsCancelDialogOpen] = useState(false)

  const currentPlan = plans?.find(
    (plan: SubscriptionPlan) => plan.planId === license?.subscriptionPlanId
  )

  const isPayPalSubscription = license?.subscriptionStatus
  const pricePerPeriod =
    parseFloat(currentPlan?.pricePerLicense) *
    license?.allowedUserCount *
    (1 + parseFloat(currentPlan?.taxes.percentage) / 100)

  const pricePerPeriodDisplay = isNaN(pricePerPeriod)
    ? '0'
    : pricePerPeriod.toFixed(2)

  const handleSubscriptionStatusChange = async () => {
    const status =
      license.subscriptionStatus === 'ACTIVE' ? 'SUSPENDED' : 'ACTIVE'
    setPayPalSubscriptionStatus({ status })
    await refreshLicense()
    setIsStatusChangeDialogOpen(false)
  }

  const handleSubscriptionCancel = async () => {
    cancelSubscription({ subscriptionId: license.subscriptionId })
    await refreshLicense()
    setIsCancelDialogOpen(false)
  }

  if (!isLicenseFetched || subscriptionsLoading) {
    return <CircularProgress />
  }

  if (!plans) {
    return null
  }
  const plan = plans[0]

  return (
    <div className={classes.root}>
      <Subscription
        name={plan.name}
        description={plan.description}
        allowedUserCount={license.allowedUserCount}
        currentUserCount={license.currentUserCount}
        subscriptionId={license.subscriptionId}
        status={license.subscriptionStatus}
        onCancel={() => setIsCancelDialogOpen(true)}
        onLicenseStatusChange={() => setIsStatusChangeDialogOpen(true)}
        license={license}
      />

      {/* Suspend or Reactivate Subscription Dialog */}
      {isPayPalSubscription && (
        <Dialog
          open={isStatusChangeDialogOpen}
          onClose={() => setIsStatusChangeDialogOpen(false)}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <DialogTitle id='alert-dialog-title'>
            {license.subscriptionStatus === 'ACTIVE'
              ? 'Are you sure you want to suspend your subscription?'
              : 'Are you sure you want to reactivate your subscription?'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id='alert-dialog-description'>
              {license.subscriptionStatus === 'ACTIVE'
                ? `Suspending your subscription will pause payments until your license is reactivated. 
              If you choose to suspend your subscription, your subscription will be available until
              ${license.subscriptionEndDate}. You can reactivate the subscription at any point in the future.`
                : `Would you like to reactivate your subscription for ${
                    license.allowedUserCount
                  } users for $${pricePerPeriodDisplay}/${currentPlan.intervalType.toLocaleLowerCase()}?`}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant='text'
              onClick={() => setIsStatusChangeDialogOpen(false)}
              className={classes.keepSubscription}
            >
              {license.subscriptionStatus === 'ACTIVE'
                ? 'No, keep subscription'
                : 'No, do not reactivate.'}
            </Button>
            <Button
              variant='contained'
              onClick={handleSubscriptionStatusChange}
            >
              {license.subscriptionStatus === 'ACTIVE'
                ? ' Yes, suspend subscriptionn'
                : 'Yes, reactivate subscription.'}
            </Button>
          </DialogActions>
        </Dialog>
      )}

      {isPayPalSubscription && (
        // Cancel Subscription Dialog
        <Dialog
          open={isCancelDialogOpen}
          onClose={() => setIsCancelDialogOpen(false)}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <DialogTitle id='alert-dialog-title'>
            {'Are you sure you want to cancel your subscription?'}
          </DialogTitle>
          <DialogContent>
            <Stack direction='column' alignItems='center'>
              <DialogContentText id='alert-dialog-description'>
                If you choose to cancel, your subscription will be available
                until {`${license.subscriptionEndDate}`}. After this date, your
                license will be removed, and you will lose all data associated
                with this license.
              </DialogContentText>
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button
              variant='text'
              onClick={() => setIsCancelDialogOpen(false)}
              className={classes.keepSubscription}
            >
              No, keep subscription
            </Button>
            <Button variant='contained' onClick={handleSubscriptionCancel}>
              Yes, cancel subscription
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  )
}
