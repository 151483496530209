import React, { useState } from 'react'
import {
  Theme,
  Typography,
  useTheme,
  Fade,
  Stack,
  Button,
  Box,
} from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
import {
  ErrorOutlineOutlined,
  ReportProblemOutlined,
  InfoOutlined,
  CheckCircleOutlined,
} from '@mui/icons-material'

import { StatusMessageInfo } from '../../../models'

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(1),
        margin: `${theme.spacing(1)} 0`,
      },
      title: {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
      },
      icon: {
        marginRight: theme.spacing(1),
      },
      messageContainer: {
        width: '100%',
        marginLeft: theme.spacing(0.5),
      },
    }),
  { name: 'status-message' }
)

export function StatusMessage(props: StatusMessageProps) {
  const { status, selfFix } = props

  const classes = useStyles()
  const theme = useTheme()

  const [readMore, setReadMore] = useState(false)

  function getStatusIconColor(): any {
    switch (status.type) {
      case 'success':
        return [CheckCircleOutlined, theme.palette.success.main]
      case 'error':
        return [ErrorOutlineOutlined, theme.palette.error.main]
      case 'warning':
        return [ReportProblemOutlined, theme.palette.warning.main]
      case 'info':
        return [InfoOutlined, theme.palette.info.main]
    }
  }

  if (!status) return null

  let [StatusIcon, statusColor] = getStatusIconColor()

  const ReadMoreMessage = () => {
    if (!readMore) return null
    return (
      <Stack direction='row' alignItems='center' gap={1}>
        <Typography variant='body2' display={'inline'}>
          {readMore && status.message && (
            <Typography variant='body2' display={'inline'}>
              {status.message}
            </Typography>
          )}
        </Typography>
      </Stack>
    )
  }

  return (
    <Fade in>
      <Stack
        direction={'column'}
        className={classes.root}
        style={{ backgroundColor: statusColor + '3B' }}
        gap={1}
      >
        <Stack
          direction={'row'}
          justifyContent={'space-between'}
          sx={{
            position: 'relative',
            width: '100%',
          }}
        >
          <Box className={classes.title}>
            <StatusIcon
              className={classes.icon}
              style={{ color: statusColor }}
            />
            <Typography variant='body2' style={{ fontWeight: 700 }}>
              {status.title}
            </Typography>
            {status.type === 'error' && status.message && (
              <Button
                onClick={() => setReadMore(!readMore)}
                sx={{
                  color: 'error.main',
                  height: 'auto',
                  fontSize: 12,
                  fontStyle: 'italic',
                  width: '80px',
                }}
              >
                {readMore ? 'hide error' : 'show error'}
              </Button>
            )}
          </Box>

          {status.type === 'error' &&
            !selfFix?.isError &&
            selfFix?.callback && (
              <>
                <Button
                  sx={{
                    height: 'auto',
                    width: '60px',
                    color: 'black',
                    borderColor: 'black',
                  }}
                  // color='secondary'
                  variant='outlined'
                  onClick={async () => {
                    await selfFix.callback()
                  }}
                  disabled={selfFix.isLoading}
                >
                  <Typography fontWeight={600} fontSize={12}>
                    {selfFix.title || 'Fix'}
                  </Typography>
                </Button>
              </>
            )}
        </Stack>
        {status?.message && <ReadMoreMessage />}
      </Stack>
    </Fade>
  )
}

type SelfFixOptions = {
  callback?: () => Promise<void>
  title?: string
  isLoading?: boolean
  isError?: boolean
}

type StatusMessageProps = React.HtmlHTMLAttributes<HTMLDivElement> & {
  status: StatusMessageInfo
  selfFix?: SelfFixOptions
}
