import { useEffect, useState } from 'react'
import {
  Button,
  Theme,
  Typography,
  Stack,
  useTheme,
  alpha,
  Paper,
} from '@mui/material'
import { makeStyles, createStyles } from '@mui/styles'
import { ColorPickerField } from './color-picker-field'
import {
  useCreateTheme,
  useGetAppSettings,
  useGetLicense,
  useGetTheme,
} from '../../../../hooks'
import { defaultTheme } from '../../../../utils/theme'
import { FullPageLoader } from '../../../../components'
import { PowerBITheme } from '../../../../models'
import { ImageSelector } from './image-selector'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '100%',
      width: '100%',
    },
    addLogo: {
      mt: 1,
    },
    addLogoBox: {
      height: '300px',
      width: '300px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#f5f5f5',
      '&:hover': {
        backgroundColor: alpha(theme.palette.primary.primaryColor400, 0.3),
      },
    },
    secondaryColor: {
      color: theme.palette.primary.primaryColor100,
    },
    currentLogo: {
      padding: theme.spacing(2),
      maxWidth: '175px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    themeBox: {
      borderRadius: theme.shape.borderRadius,
      padding: theme.spacing(2),
      marginRight: theme.spacing(2),
      justifyContent: 'space-between',
      display: 'flex',
      flexDirection: 'column',
      minWidth: '300px',
    },
    themeBoxStatus: {
      justifyContent: 'flex-start',
    },
    themeContainer: {
      display: 'flex',
      flexDirection: 'row',
    },
  })
)

export function AppearanceConfig() {
  const classes = useStyles()
  const { mutate: saveTheme } = useCreateTheme()
  const { data: appSettings } = useGetAppSettings()

  const { data: themeForLicense, isLoading: themeLoading } = useGetTheme()
  const { data: license, isLoading: licenseLoading } = useGetLicense()

  const isLoading = themeLoading || licenseLoading

  const currentTheme = useTheme()

  const [primaryColor500, setPrimaryColor500] = useState(
    currentTheme.palette.primary?.primaryColor500
  )
  const [primaryColor400, setPrimaryColor400] = useState(
    currentTheme.palette.primary?.primaryColor400
  )
  const [primaryColor300, setPrimaryColor300] = useState(
    currentTheme.palette.primary?.primaryColor300
  )
  const [primaryColor200, setPrimaryColor200] = useState(
    currentTheme.palette.primary?.primaryColor200
  )
  const [primaryColor100, setPrimaryColor100] = useState(
    currentTheme.palette.primary?.primaryColor100
  )
  const [primaryColor50, setPrimaryColor50] = useState(
    currentTheme.palette.primary?.primaryColor50
  )

  const [successColor, setSuccessColor] = useState(
    currentTheme.palette.success.main
  )
  const [warningColor, setWarningColor] = useState(
    currentTheme.palette.warning.main
  )
  const [backgroundColor, setBackgroundColor] = useState(
    currentTheme.palette.background.default
  )
  const [errorColor, setErrorColor] = useState(currentTheme.palette.error.main)
  // const [mode, setMode] = useState(currentTheme.palette.mode)

  const [logo, setLogo] = useState(themeForLicense?.logo)

  useEffect(() => {
    setPrimaryColor500(currentTheme.palette.primary?.primaryColor500)
    setPrimaryColor400(currentTheme.palette.primary?.primaryColor400)
    setPrimaryColor300(currentTheme.palette.primary?.primaryColor300)
    setPrimaryColor200(currentTheme.palette.primary?.primaryColor200)
    setPrimaryColor100(currentTheme.palette.primary?.primaryColor100)
    setPrimaryColor50(currentTheme.palette.primary?.primaryColor50)

    setSuccessColor(currentTheme.palette.success.main)
    setWarningColor(currentTheme.palette.warning.main)
    setErrorColor(currentTheme.palette.error.main)
    setBackgroundColor(currentTheme.palette.background.default)
    if (themeForLicense?.logo) setLogo(themeForLicense.logo)
  }, [currentTheme, themeForLicense])

  async function resetToDefault() {
    saveTheme({ licenseNodeId: license.id, theme: defaultTheme })
  }

  async function updateTheme() {
    const newTheme: PowerBITheme = {
      ...defaultTheme,
      palette: {
        ...defaultTheme.palette,
        // mode: mode,
        primary: {
          ...defaultTheme.palette.primary,
          primaryColor500: primaryColor500,
          primaryColor400: primaryColor400,
          primaryColor300: primaryColor300,
          primaryColor200: primaryColor200,
          primaryColor100: primaryColor100,
          primaryColor50: primaryColor50,
        },
        secondary: {
          ...defaultTheme.palette.secondary,
        },
        success: {
          ...defaultTheme.palette.success,
          main: successColor,
        },
        warning: {
          ...defaultTheme.palette.warning,
          main: warningColor,
        },
        error: {
          ...defaultTheme.palette.error,
          main: errorColor,
        },
        background: {
          ...defaultTheme.palette.background,
          default: backgroundColor,
        },
      },
      components: {
        ...defaultTheme.components,
      },
      typography: {
        ...defaultTheme.typography,
      },
      logo: logo,
    }

    saveTheme({
      licenseNodeId: license.id,
      theme: newTheme,
    })
  }
  // function toggleDarkMode(isDark: boolean) {
  //   setMode(isDark ? 'dark' : 'light')
  // }
  // const isDarkMode = mode === 'dark'

  return (
    <div className={classes.root}>
      <Typography variant='h5'>Appearance</Typography>
      <Typography variant='body2' sx={{ marginBottom: 2 }}>
        Change the theme settings
      </Typography>
      {isLoading && <FullPageLoader />}
      {!isLoading && (
        <div>
          <div className={classes.themeContainer}>
            <Paper className={classes.themeBox}>
              <Typography variant='h6'>Theme Colors</Typography>
              <ColorPickerField
                title='Primary Color 500'
                selectedColor={primaryColor500}
                onColorChanged={color => {
                  setPrimaryColor500(color)
                }}
              />
              <ColorPickerField
                title='Primary Color 400'
                selectedColor={primaryColor400}
                onColorChanged={color => {
                  setPrimaryColor400(color)
                }}
              />
              <ColorPickerField
                title='Primary Color 300'
                selectedColor={primaryColor300}
                onColorChanged={color => {
                  setPrimaryColor300(color)
                }}
              />
              <ColorPickerField
                title='Primary Color 200'
                selectedColor={primaryColor200}
                onColorChanged={color => {
                  setPrimaryColor200(color)
                }}
              />
              <ColorPickerField
                title='Primary Color 100'
                selectedColor={primaryColor100}
                onColorChanged={color => {
                  setPrimaryColor100(color)
                }}
              />
              <ColorPickerField
                title='Primary Color 50'
                selectedColor={primaryColor50}
                onColorChanged={color => {
                  setPrimaryColor50(color)
                }}
              />
              <ColorPickerField
                title='Background'
                selectedColor={backgroundColor}
                onColorChanged={color => {
                  setBackgroundColor(color)
                }}
              />
            </Paper>
            <Paper className={`${classes.themeBox} ${classes.themeBoxStatus}`}>
              <Typography variant='h6'>Status Colors</Typography>
              <ColorPickerField
                title='Success'
                selectedColor={successColor}
                onColorChanged={color => {
                  setSuccessColor(color)
                }}
              />
              <ColorPickerField
                title='Warning'
                selectedColor={warningColor}
                onColorChanged={color => {
                  setWarningColor(color)
                }}
              />
              <ColorPickerField
                title='Error'
                selectedColor={errorColor}
                onColorChanged={color => {
                  setErrorColor(color)
                }}
              />
            </Paper>
            {appSettings?.customLogoEnabled && (
              <Paper className={classes.themeBox}>
                <div>
                  <Typography variant='h6'>Theme Logo</Typography>

                  {logo && (
                    <div
                      className={classes.currentLogo}
                      style={{ backgroundColor: primaryColor400 }}
                    >
                      <img
                        src={logo}
                        alt='Logo'
                        style={{ width: '150px', marginBottom: 2 }}
                      />
                    </div>
                  )}

                  {!logo && (
                    <Typography
                      variant='body2'
                      sx={{ m: 2 }}
                      fontStyle='italic'
                    >
                      No logo set
                    </Typography>
                  )}
                </div>

                <ImageSelector
                  label='Logo'
                  setImage={setLogo}
                  image={logo}
                  isLogo={true}
                  primaryColor={primaryColor400}
                  secondaryColor={primaryColor100}
                  preview={{
                    height: '100px',
                    width: '300px',
                  }}
                />
              </Paper>
            )}
          </div>
          <Stack direction='row' gap={1} sx={{ mt: 2 }}>
            <Button
              className='button primaryButton'
              onClick={() => updateTheme()}
              size='large'
              variant='outlined'
            >
              Save Theme
            </Button>
            <Button
              className='button tertiaryButton'
              onClick={() => resetToDefault()}
              size='large'
            >
              Reset Theme
            </Button>
          </Stack>
          {/* <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  color='secondary'
                  checked={isDarkMode}
                  onChange={e => {
                    toggleDarkMode(e.target.checked)
                  }}
                />
              }
              label='Dark mode'
            />
          </FormGroup> */}
        </div>
      )}
    </div>
  )
}
