import { Button, Divider, Skeleton, Stack, Typography } from '@mui/material'

export function FolderSkeletons(props: {
  numFolders: number
  reportGroup?: boolean
}) {
  const { numFolders, reportGroup } = props
  const folderSpacing = numFolders > 2 ? 3 : numFolders > 1 ? 2 : 1

  return (
    <Stack alignItems='baseline' gap={0} sx={{ mb: 0 }}>
      {!reportGroup && (
        <Stack
          direction='column'
          sx={{
            width: '100%',
          }}
        >
          <Stack
            direction='row'
            alignItems='baseline'
            gap={1}
            sx={{ height: '25px', mb: 1 }}
          >
            <Typography variant='h5' sx={{ fontWeight: 600, display: 'block' }}>
              {'My Folders'}
            </Typography>
            <Button
              variant='text'
              sx={{
                color: theme => theme.palette.primary.primaryColor400,
                '&:hover': {
                  backgroundColor: 'transparent',
                },
              }}
              disabled
            >
              Add New Folder
            </Button>
          </Stack>
          <Divider sx={{ width: '100%' }} />
        </Stack>
      )}
      <Stack
        direction='row'
        gap={1}
        sx={{
          whiteSpace: 'wrap',
          flexWrap: 'wrap',
          overflow: 'auto',
          width: '100%',
          mt: 2,
        }}
      >
        {[...Array(numFolders)].map((_, index) => (
          <Skeleton
            key={index}
            variant='rectangular'
            sx={{
              fontSize: '16px',
              width: `calc(100% / ${folderSpacing} - ${
                folderSpacing === 1 ? 0 : 32
              }px)`,
              minWidth: theme => theme.spacing(46.5),
              borderRadius: '5px',
              border: '1px solid #dce2e8',
              maxWidth: '880px',
              height: '40px',
              overflow: 'hidden',
            }}
          />
        ))}
      </Stack>
    </Stack>
  )
}
