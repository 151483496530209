import { Stack, Switch, Theme, Typography } from '@mui/material'
import { useState } from 'react'
import { FormField } from '../../admin/shared'
import { createStyles, makeStyles } from '@mui/styles'
import { useGetWorkspaces } from '../../../hooks'
import { SkeletonFormField } from '../../admin/tabs'
import CustomDialog from '../../admin/shared/dialog/dialog'
import { WorkspaceInfo } from '../../../models'

function containsSpecialChars(str: string): boolean {
  const specialChars = /[^a-zA-Z0-9 )(\-_.,/]/
  return specialChars.test(str)
}

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      title: {
        padding: 0,
        margin: '16px 0',
      },
      dialogContent: {
        display: 'block',
        padding: theme.spacing(4),
      },
      error: {
        width: '100%',
        color: 'red',
      },
      form: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'flex-end',
      },
    }),
  { name: 'copy-dialog' }
)

export function ReportCopyDialog(props: ReportCopyDialogProps) {
  const { isOpen, onClose, setCopyReport } = props

  const classes = useStyles()
  const [tempCopyName, setTempCopyName] = useState('')
  const [tempDescription, setTempDescription] = useState('')
  const [workspace, setWorkspace] = useState<WorkspaceInfo | null>(null)

  const { data: workspaces, isLoading: loadingWorkspaces } = useGetWorkspaces()

  const [titleErrorMessage, setTitleErrorMessage] = useState('')
  const [descriptionErrorMessage, setDescriptionErrorMessage] = useState('')

  const [sharedWithLicense, setSharedWithLicense] = useState<boolean>(false)

  const handleClose = () => {
    onClose()
    setTempCopyName('')
  }

  function WorkspaceFormField() {
    if (loadingWorkspaces) {
      return <SkeletonFormField />
    }

    //If there is only one option, auto select it
    if (workspaces?.length === 1 && !workspace) {
      setWorkspace({
        id: workspaces[0].id,
        name: workspaces[0].name,
        isReadOnly: workspaces[0].isReadOnly,
        profileId: workspaces[0].profileId,
        pbiWorkspaceId: workspaces[0].pbiWorkspaceId,
      })
    }

    return (
      <FormField
        label='Workspace'
        value={workspace?.name || ''}
        helperText=''
        selectOptions={workspaces?.map(ws => ws.name) || []}
        onTextChange={value => {
          const selectedWorkspace = workspaces?.find(ws => ws.name === value)
          if (!selectedWorkspace) return
          setWorkspace({
            id: selectedWorkspace.id,
            name: selectedWorkspace.name,
            isReadOnly: selectedWorkspace.isReadOnly,
            profileId: selectedWorkspace.profileId,
            pbiWorkspaceId: selectedWorkspace.pbiWorkspaceId,
          })
        }}
      />
    )
  }
  function SharedWithLicenseFormField() {
    return (
      <Stack direction='row' alignItems='center' justifyContent='space-between'>
        <Typography>Share with License</Typography>
        <Switch
          checked={sharedWithLicense}
          onChange={e => {
            setSharedWithLicense(e.target.checked)
          }}
        />
      </Stack>
    )
  }

  return (
    <CustomDialog
      open={isOpen}
      onClose={handleClose}
      title={'Save a Copy'}
      primaryButtonProps={{
        children: 'Confirm',
        disabled:
          !workspace || !!titleErrorMessage || tempCopyName.length === 0,
        onClick: async () => {
          setCopyReport({
            name: tempCopyName,
            description: tempDescription,
            workspace: workspace,
            shareWithLicense: sharedWithLicense,
          })
        },
      }}
      secondaryButtonProps={{
        children: 'Cancel',
        onClick: () => {
          onClose()
        },
      }}
    >
      <form
        className={classes.form}
        onSubmit={e => {
          e.preventDefault()
        }}
      >
        <FormField
          required
          label='Title of Copy'
          value={tempCopyName}
          errorMessage={titleErrorMessage}
          onTextChange={value => {
            if (value.length > 50) {
              setTitleErrorMessage('This field cannot exceed 50 characters')
            } else if (value.toLowerCase() === 'new report') {
              setTitleErrorMessage('Please enter a valid title')
              setTempCopyName(value)
            } else if (containsSpecialChars(value)) {
              setTitleErrorMessage(
                'This field cannot contain the following characters: ~ ! @ # $ % ^ & * < > ? | ; : \' "  \\ '
              )
            } else {
              setTitleErrorMessage('')
              setTempCopyName(value)
            }
          }}
        />
        <FormField
          isTextArea={true}
          label='Description'
          value={tempDescription}
          errorMessage={descriptionErrorMessage}
          onTextChange={value => {
            if (value.length > 200) {
              setDescriptionErrorMessage(
                'This field cannot exceed 200 characters'
              )
            } else {
              setDescriptionErrorMessage('')
              setTempDescription(value)
            }
          }}
        />
        <WorkspaceFormField />
        <SharedWithLicenseFormField />
      </form>
    </CustomDialog>
  )
}

type ReportCopyDialogProps = {
  isOpen: boolean
  onClose: () => void
  setCopyReport: (report: {
    name: string
    description: string
    shareWithLicense: boolean
    workspace: WorkspaceInfo
  }) => void
}
