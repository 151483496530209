import { useEffect } from 'react'
import {
  AdvancedFilterType,
  DataColumnWithFilters,
  getAdvancedFilterOptionsByDataType,
} from '../custom-report-table'
import { alpha, Icon, Radio, Stack, Typography } from '@mui/material'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'
import { SetFilter } from './set-filters'

type AdvancedFilterProps = {
  dataColumnWithFilters: DataColumnWithFilters
  setFilterConnector: (value: string) => void //AND || OR
  currentAdvancedFilters: AdvancedFilterType[]
  setCurrentAdvancedFilters: (filters: AdvancedFilterType[]) => void
  showSecondFilter: boolean
  setShowSecondFilter: (value: boolean) => void
  orValue: boolean
  setOrValue: (value: boolean) => void
  andValue: boolean
  setAndValue: (value: boolean) => void
}

export function AdvancedFilter(props: AdvancedFilterProps) {
  const {
    dataColumnWithFilters,
    setFilterConnector,
    currentAdvancedFilters,
    setCurrentAdvancedFilters,
    showSecondFilter,
    setShowSecondFilter,
    orValue,
    setOrValue,
    andValue,
    setAndValue,
  } = props

  const filterOptions = getAdvancedFilterOptionsByDataType(
    dataColumnWithFilters.dataType
  )

  useEffect(() => {
    setFilterConnector(andValue ? 'AND' : 'OR')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [andValue, orValue])

  return (
    <>
      <SetFilter
        filter={currentAdvancedFilters?.[0]}
        onFilterChange={filter => {
          let newFilters = [...currentAdvancedFilters]
          newFilters[0] = filter as AdvancedFilterType
          setCurrentAdvancedFilters(newFilters)
        }}
        filterOptions={filterOptions}
        dataType={dataColumnWithFilters.dataType}
      />

      {showSecondFilter && (
        <>
          <AndOrButtons
            orValue={orValue}
            onOrChange={setOrValue}
            andValue={andValue}
            onAndChange={setAndValue}
          />
          <SetFilter
            filter={currentAdvancedFilters?.[1]}
            onFilterChange={filter => {
              let newFilters = [...currentAdvancedFilters]
              newFilters[1] = filter as AdvancedFilterType
              setCurrentAdvancedFilters(newFilters)
            }}
            filterOptions={filterOptions}
            dataType={dataColumnWithFilters.dataType}
          />
        </>
      )}
      <AddOrRemoveExtraFilter
        isOpen={showSecondFilter}
        onButtonClick={() => setShowSecondFilter(!showSecondFilter)}
      />
    </>
  )
}

type AndOrButtonsProps = {
  orValue: boolean
  onOrChange: (value: boolean) => void
  andValue: boolean
  onAndChange: (value: boolean) => void
}
export function AndOrButtons(props: AndOrButtonsProps) {
  const { orValue, onOrChange, andValue, onAndChange } = props

  const handleAndChange = (value: boolean) => {
    if (orValue && value) {
      onOrChange(false)
    }
    onAndChange(true)
  }

  const handleOrChange = (value: boolean) => {
    if (andValue && value) {
      onAndChange(false)
    }
    onOrChange(true)
  }

  return (
    <Stack direction={'row'} gap={3} alignItems={'center'}>
      <Stack direction={'row'} alignItems={'center'}>
        <RadioButton value={orValue} onChange={handleOrChange} label='OR' />
        <Typography>Or</Typography>
      </Stack>
      <Stack direction={'row'} alignItems={'center'}>
        <RadioButton value={andValue} onChange={handleAndChange} label='AND' />
        <Typography>And</Typography>
      </Stack>
    </Stack>
  )
}

type RadioButtonProps = {
  value: boolean
  onChange: (value: boolean) => void
  label: string
}

function RadioButton(props: RadioButtonProps) {
  const { value, onChange } = props
  return (
    <Radio
      size='small'
      checked={value}
      onClick={e => {
        e.stopPropagation()
        onChange(!value)
      }}
    />
  )
}

type AddOrRemoveExtraFilterProps = {
  onButtonClick: () => void
  isOpen: boolean
}

function AddOrRemoveExtraFilter(props: AddOrRemoveExtraFilterProps) {
  const { onButtonClick, isOpen } = props

  return (
    <Stack
      direction={'row'}
      alignItems={'center'}
      onClick={e => {
        e.stopPropagation()
        onButtonClick()
      }}
      sx={{
        cursor: 'pointer',
        '&:hover': {
          color: theme => alpha(theme.palette.primary.primaryColor400, 0.9),
        },
        borderRadius: '4px',
        height: '20px',
      }}
      gap={0.1}
    >
      <Icon
        sx={{
          fontSize: '16px',
        }}
      >
        {isOpen ? (
          <RemoveCircleOutlineIcon
            sx={{
              fontSize: '16px',
              mb: 0.5,
            }}
          />
        ) : (
          <AddCircleOutlineIcon
            sx={{
              fontSize: '16px',
              mb: 0.5,
            }}
          />
        )}
      </Icon>
      <Typography fontSize={'12px'}>
        {isOpen ? 'Remove condition' : 'Add condition'}
      </Typography>
    </Stack>
  )
}
