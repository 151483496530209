import { Stack, TextField, Typography } from '@mui/material'
import { useState } from 'react'

type EditableTextProps = {
  name: string
  setName: (name: string) => void
  checkForIllegalChars?: boolean
  illegalChars?: string[]
}

export const EditableText = (props: EditableTextProps) => {
  const { name, setName, illegalChars } = props

  const [editing, setEditing] = useState(false)
  const [editedName, setEditedName] = useState(name)
  const [error, setError] = useState(false)

  const handleNameClick = () => {
    setEditing(true)
  }

  const handleNameChange = (e: any) => {
    if (
      illegalChars &&
      illegalChars.some(char => e.target.value.includes(char))
    ) {
      setError(true)
    } else {
      setError(false)
    }

    setEditedName(e.target.value)
  }

  const handleNameBlur = () => {
    setEditing(false)
    setName(editedName) // Call the setName prop with the updated name
  }

  const handleKeyPress = (e: any) => {
    if (e.key === 'Enter') {
      setEditing(false)
      setName(editedName) // Call the setName prop with the updated name
    }
  }

  return (
    <Stack
      height={'20px'}
      width={'80%'}
      direction='row'
      alignItems='center'
      gap={1}
      onBlur={handleNameBlur}
    >
      {editing ? (
        <TextField
          value={editedName}
          onChange={handleNameChange}
          onBlur={handleNameBlur}
          onKeyDown={handleKeyPress}
          autoFocus
          error={error}
          onFocus={e => e.target.select()}
          sx={{
            '& .MuiInputBase-root': {
              border: 'none',
            },
            '& .MuiInputBase-input': {
              fontSize: '1.5rem',
              color: theme =>
                error
                  ? theme.palette.error.main
                  : theme.palette.primary.primaryColor400,
              padding: 0,
              alignSelf: 'center',
            },
            '& .MuiOutlinedInput-notchedOutline': {
              border: 'none !important',
            },
            width: '100%',
            mb: '-4px !important',
            height: '30px',
            minHeight: 0,
            padding: 0,
            margin: 0,
            alignSelf: 'flex-end',
          }}
        />
      ) : (
        <Typography
          variant='h5'
          onClick={handleNameClick}
          sx={{
            '&:hover': {
              cursor: 'pointer',
            },
            color: theme => theme.palette.primary.primaryColor400,
          }}
          color={theme => theme.palette.grey[600]}
        >
          {name}
        </Typography>
      )}
    </Stack>
  )
  // return <Options options={options} show={show} isPortrait={isPortrait} />
}
