import TableChartOutlinedIcon from '@mui/icons-material/TableChartOutlined' // Paginated report icon
import GridViewIcon from '@mui/icons-material/GridView' //Custom report builder icon
import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined' // Regular report icon
import { SxProps } from '@mui/material'
import { FolderItem } from '../../../../models'

export function getItemIconByType(type: string, sx?: SxProps) {
  if (type === 'dataset') {
    return <GridViewIcon sx={sx} />
  }
  if (type === 'paginated') {
    return <TableChartOutlinedIcon sx={sx} />
  }
  if (type === 'bookmark') {
    return <BarChartOutlinedIcon sx={sx} /> //<BookmarkBorderIcon sx={sx} />
  }
  return <BarChartOutlinedIcon sx={sx} />
}

export function getItemPath(item: FolderItem) {
  if (item.type === 'bookmark' && item.reportId) {
    return `/reports/${item.reportId}/${item.id}`
  }
  return `/reports/${item.id}`
}
