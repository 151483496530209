import { useMutation } from 'react-query'
import { workspacesApi } from '../../api-interface/workspace-api'
import { useRecoilValue } from 'recoil'
import { licenseAtom } from '../../state'

export const postDaxQuery = 'post-dax-query'

export type PostDaxQueryPayload = {
  appRegNodeId?: string
  datasetId?: string
  groupId?: string
  reportId?: string
  query: string
}

export function usePostDaxQuery() {
  const licenseId = useRecoilValue(licenseAtom)
  return useMutation((payload: PostDaxQueryPayload) =>
    workspacesApi.postDaxQuery(
      {
        appRegNodeId: payload.appRegNodeId,
        datasetId: payload.datasetId,
        groupId: payload.groupId,
        reportId: payload.reportId,
        query: payload.query,
      },
      licenseId
    )
  )
}
