import { FC, PropsWithChildren } from 'react'
import {
  Typography,
  Theme,
  Stack,
  SvgIconProps,
  Snackbar,
  ClickAwayListener,
} from '@mui/material'
import { SxProps } from '@mui/system'
import { StyledToastBox } from './Toast.styles'

export type ToastProps = {
  open: boolean
  message: string | React.ReactElement
  onClose?: () => void
  variant?: 'primary' | 'success' | 'warning' | 'error'
  icon?: React.ReactElement<SvgIconProps>
  autoTimeout?: number
  sx?: SxProps<Theme>
}

export const ToastNotification: FC<PropsWithChildren<ToastProps>> = (
  props: PropsWithChildren<ToastProps>
) => {
  const setBackgroundColor = (theme: Theme) => {
    let backgroundColor = undefined
    switch (props.variant) {
      case 'primary':
        backgroundColor = theme.palette?.primary?.primaryColor400
        break
      case 'success':
        backgroundColor = theme.palette?.success?.main
        break
      case 'warning':
        backgroundColor = theme.palette?.warning?.main
        break
      case 'error':
        backgroundColor = theme.palette?.error?.main
        break
      default:
        break
    }
    return backgroundColor
  }

  return (
    <ClickAwayListener onClickAway={() => props.onClose?.()}>
      <Snackbar
        open={props.open}
        onClose={() => props.onClose?.()}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        sx={{
          top: '0 !important',
          width: '100%',
          ...(props.sx as {}),
        }}
        ClickAwayListenerProps={{ onClickAway: () => undefined }}
        autoHideDuration={props?.autoTimeout || 6000}
      >
        <StyledToastBox
          sx={{
            backgroundColor: (theme: Theme) => setBackgroundColor(theme),
            color: (theme: Theme) => theme.palette.common.white,
          }}
        >
          <Stack
            direction='row'
            alignItems='center'
            justifyContent='center'
            sx={{ width: '100%' }}
            gap={2}
          >
            {props.icon}
            <Typography
              variant='body1'
              sx={{
                color: (theme: Theme) => theme.palette.common.white,
              }}
            >
              {props.message}
            </Typography>
          </Stack>
        </StyledToastBox>
      </Snackbar>
    </ClickAwayListener>
  )
}
