import { useState } from 'react'

import { FormField, StatusMessage } from '../../shared'
import {
  useCreateAppReg,
  useGetLicense,
  useUpdateAppReg,
  useDeleteAppReg,
} from '../../../../hooks'
import { AppRegistration, StatusMessageInfo } from '../../../../models'
import CustomDialog from '../../shared/dialog/dialog'

function containsSpecialChars(str: string): boolean {
  const specialChars = /[^a-zA-Z0-9 )(\-_.,/]/
  return specialChars.test(str)
}

export function AddAppRegistrationForm(props: AddAppRegistrationContentProps) {
  const {
    onClose,
    onSave,
    onDelete,
    isEdit,
    appRegistrationToEdit,
    open,
    title,
  } = props
  const { mutateAsync: createAppReg, isLoading: isSaving } = useCreateAppReg()
  const { mutateAsync: updateAppReg, isLoading: isUpdating } = useUpdateAppReg()
  const { mutateAsync: deleteAppReg, isLoading: isDeleting } = useDeleteAppReg()

  const [appRegistrationSecret, setAppRegistrationSecret] = useState(
    isEdit ? 'somereallylongplaceholdervalueforafakepassword' : ''
  )
  const { data: license } = useGetLicense()
  const [status, setStatus] = useState<StatusMessageInfo | null>(null)

  const [appRegistrationId, setAppRegistrationId] = useState(
    appRegistrationToEdit?.appRegistrationId ?? ''
  )

  const [tenantId, setTenantId] = useState(
    appRegistrationToEdit?.tenantId ?? ''
  )

  const [appName, setAppName] = useState(appRegistrationToEdit?.name ?? '')

  const [displayNameErrorMessage, setDisplayNameErrorMessage] = useState('')
  const [applicationIdErrorMessage, setApplicationIdErrorMessage] = useState('')
  const [directoryIdErrorMessage, setDirectoryIdErrorMessage] = useState('')
  const [clientSecretErrorMessage, setClientSecretErrorMessage] = useState('')

  async function handleSave() {
    let appReg = {
      licenseNodeId: license.id,
      appRegistrationId,
      tenantId,
      name: appName,
      appRegistrationSecret,
    }

    try {
      if (!isEdit) {
        await createAppReg(appReg)
      } else {
        await updateAppReg({ ...appReg, id: appRegistrationToEdit.id })
      }

      onSave()
      onClose()
    } catch (error) {
      setStatus({
        type: 'error',
        message: `An error occurred while saving the app registration ${error?.response?.data?.errorMessage}`,
        title: 'Error',
      })
    }
  }

  async function handleDelete() {
    try {
      if (!isEdit) {
        onClose()
      } else {
        await deleteAppReg(appRegistrationToEdit.id)
      }
      onDelete()
      onClose()
    } catch (error) {
      setStatus({
        type: 'error',
        message: `An error occurred while deleting the app registration ${error?.response?.data?.errorMessage}`,
        title: 'Error',
      })
    }
  }

  return (
    <CustomDialog
      title={title}
      open={open}
      onClose={() => onClose()}
      primaryButtonProps={{
        onClick: () => handleSave(),
        children: 'Save',
      }}
      secondaryButtonProps={{
        onClick: () => onClose(),
        children: 'Cancel',
      }}
      tertiaryButtonProps={
        title === 'Edit App Registration'
          ? {
              onClick: () => handleDelete(),
              children: 'Delete',
            }
          : null
      }
      loading={isSaving || isUpdating || isDeleting}
    >
      <FormField
        label='Display Name'
        value={appName}
        helperText=''
        onTextChange={value => {
          if (value.length > 100) {
            setDisplayNameErrorMessage(
              'This field cannot exceed 100 characters'
            )
          } else if (containsSpecialChars(value)) {
            setDisplayNameErrorMessage(
              'This field cannot contain the following characters: ~ ! @ # $ % ^ & * < > ? | ; : \' "  \\ '
            )
          } else {
            setDisplayNameErrorMessage('')
            setAppName(value)
          }
        }}
        errorMessage={displayNameErrorMessage}
      />
      <FormField
        label='Application (client) ID'
        value={appRegistrationId}
        helperText='Client Id for the app registration'
        onTextChange={value => {
          if (value.length > 100) {
            setApplicationIdErrorMessage(
              'This field cannot exceed 100 characters'
            )
          } else {
            setApplicationIdErrorMessage('')
            setAppRegistrationId(value)
          }
        }}
        disabled={isEdit}
        errorMessage={applicationIdErrorMessage}
      />
      <FormField
        label='Directory (tenant) ID'
        value={tenantId}
        helperText='The Azure Directory that the service principal belongs to'
        onTextChange={value => {
          if (value.length > 100) {
            setDirectoryIdErrorMessage(
              'This field cannot exceed 100 characters'
            )
          } else {
            setDirectoryIdErrorMessage('')
            setTenantId(value)
          }
        }}
        disabled={isEdit}
        errorMessage={directoryIdErrorMessage}
      />
      <FormField
        label='Client Secret'
        value={appRegistrationSecret}
        helperText='This is the client secret (application password)'
        onTextChange={value => {
          if (value.length > 200) {
            setClientSecretErrorMessage(
              'This field cannot exceed 200 characters'
            )
          } else {
            setClientSecretErrorMessage('')
            setAppRegistrationSecret(value)
          }
        }}
        type='password'
        disabled={isEdit}
        errorMessage={clientSecretErrorMessage}
      />
      {status && <StatusMessage status={status} />}
    </CustomDialog>
  )
}

export type AddAppRegistrationContentProps = {
  onClose?: () => void
  onSave?: () => void
  onDelete?: () => void
  isEdit?: boolean
  appRegistrationToEdit?: AppRegistration
  open: boolean
  title: string
}
