import { useMutation, useQueryClient } from 'react-query'
import { appRegApi } from '../../api-interface'
import { getAppRegistrationsKey } from '../queries'
import { AppRegistrationState } from '../../models'
import { useRecoilValue } from 'recoil'
import { licenseAtom } from '../../state'

type DeleteAppRegContext = {
  previousAppRegs: AppRegistrationState[]
}

export function useDeleteAppReg() {
  const queryClient = useQueryClient()
  const licenseId = useRecoilValue(licenseAtom)

  return useMutation(
    (appRegId: string) => appRegApi.deleteAppRegistration(appRegId, licenseId),
    {
      async onMutate(
        newAppRegistrationId: string
      ): Promise<DeleteAppRegContext> {
        await queryClient.cancelQueries([getAppRegistrationsKey, licenseId])

        let previousAppRegs: AppRegistrationState[] = queryClient.getQueryData([
          getAppRegistrationsKey,
          licenseId,
        ])
        queryClient.setQueryData(
          [getAppRegistrationsKey, licenseId],
          (old: AppRegistrationState[]) =>
            old.filter(appReg => appReg.id !== newAppRegistrationId)
        )

        return { previousAppRegs }
      },
      onError(_err, _newAppRegId, context: DeleteAppRegContext) {
        queryClient.setQueryData(
          [getAppRegistrationsKey, licenseId],
          context.previousAppRegs
        )
      },
      onSettled() {
        queryClient.invalidateQueries([getAppRegistrationsKey, licenseId])
      },
    }
  )
}
