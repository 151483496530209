import { useHistory } from 'react-router-dom'
import { useRoutes } from '../../../../hooks'
import { useState } from 'react'
import {
  Icon,
  Popover,
  Stack,
  Typography,
  CircularProgress,
  Theme,
  alpha,
} from '@mui/material'
import StarBorderIcon from '@mui/icons-material/StarBorder'
import StarIcon from '@mui/icons-material/Star'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import { Folder, FolderItem } from '../../../../models'
import { useCreateFolder } from '../../../../hooks/mutations/useCreateFolder'
import { useUpdateFolder } from '../../../../hooks/mutations/useUpdateFolder'
import { AddToFolderOptions } from './add-to-folder-options'
import { CreateNewFolderOutlined } from '@mui/icons-material'
import { useAddItemsToFavorites } from '../../../../hooks/mutations/useAddItemToFavoritesFolder'
import { useRemoveItemFromFavorites } from '../../../../hooks/mutations/useRemoveItemFromFavoritesFolder'
import { getItemIconByType } from './folder-utilities'
import { makeStyles, createStyles } from '@mui/styles'
import BookmarkIcon from '@mui/icons-material/Bookmark'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    itemContainer: {
      borderTop: '1px solid #F2F2F2',
      borderRadius: '0px',
      paddingTop: theme.spacing(0.5),
      paddingBottom: theme.spacing(0.5),
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      transition: '1.5s',
      '&:hover': {
        color: theme.palette.primary.dark,
        textDecoration: 'underline',
        textDecorationThickness: '1px',
        textUnderlineOffset: theme.spacing(0.5),
      },
      width: '100%',
      gap: theme.spacing(1),
      justifyContent: 'space-between',
    },
    itemContent: {
      width: '100%',
      alignItems: 'center',
      gap: theme.spacing(1),
      justifyContent: 'space-between',
    },
    itemContentPart: {
      gap: theme.spacing(1),
    },
    itemName: {
      display: 'flex',
      alignItems: 'center',
      gap: '5px',
      paddingBottom: '2px',
      fontWeight: 300,
      fontSize: '16px',
      color: theme.palette.primary.dark,
      overflow: 'hidden',
      textWrap: 'wrap',
      textAlign: 'left',
      //textOverflow: 'ellipsis',
      //maxWidth: '350px',
    },
  })
)

type FolderItemProps = {
  item: FolderItem
  provided: any
  onRemove: () => void
  disableEdit?: boolean
  foldersAvailable: Folder[]
}

export function SingleFolderItem(props: FolderItemProps) {
  const { item, provided, onRemove, disableEdit, foldersAvailable } = props

  const routes = useRoutes()
  const history = useHistory()
  const classes = useStyles()

  const { mutateAsync: addToFavorites } = useAddItemsToFavorites()
  const { mutateAsync: removeFromFavorites } = useRemoveItemFromFavorites()

  const [isAddingToFolder, setIsAddingToFolder] = useState(false)
  const [isFavoritesLoading, setIsFavoritesLoading] = useState(false)

  const { mutateAsync: updateFolder } = useUpdateFolder()
  const { mutateAsync: createFolder } = useCreateFolder()

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const handleFavoriteClicked = async () => {
    setIsFavoritesLoading(true)
    if (item.isFavorite) {
      await removeFromFavorites({ itemId: item.id })
    } else {
      await addToFavorites({ item: item })
    }
    setTimeout(() => {
      setIsFavoritesLoading(false)
      handlePopoverClose()
    }, 1000)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
    setIsAddingToFolder(false)
  }

  const onItemClicked = () => {
    if (item.accessDenied) return
    //set URL based on item type
    if (item.type === 'bookmark' && item.reportId) {
      history.push(
        routes.reports.report({
          id: item.reportId,
          bookmark: item.id,
        })
      )
      return
    }

    history.push(
      routes.reports.report({
        id: item.id,
      })
    )
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  return (
    <Stack
      direction='row'
      alignItems='center'
      justifyContent={'space-between'}
      gap={1}
      className={classes.itemContainer}
      onClick={onItemClicked}
      ref={provided.innerRef}
      {...(!disableEdit ? provided.draggableProps : {})}
      sx={{
        cursor: item.accessDenied ? 'default' : 'pointer',
      }}
    >
      <Stack direction='row' className={classes.itemContent}>
        <Stack direction='row' className={classes.itemContentPart}>
          <Icon
            {...provided.dragHandleProps}
            sx={{
              display: 'flex',
              alignItems: 'center',
              cursor: disableEdit ? 'inherit' : 'grab',
              color: theme => theme.palette.primary.primaryColor100,
            }}
          >
            {getItemIconByType(item.type, { mb: 0.5 })}
          </Icon>
          <Typography className={classes.itemName}>
            {item.name}
            {item.type === 'bookmark' ? (
              <BookmarkIcon
                sx={{
                  color: theme => theme.palette.info.dark,
                  fontSize: '18px',
                }}
              />
            ) : null}
          </Typography>
          {item.accessDenied && (
            <Typography
              display='inline-block'
              sx={{
                color: theme => theme.palette.error.main,
                fontStyle: 'italic',
              }}
            >
              [Access Denied]
            </Typography>
          )}
        </Stack>
        <Stack direction={'row'} alignItems={'center'} gap={1}>
          <Stack
            sx={{
              width: '24px',
              color: theme => alpha(theme.palette.primary.primaryColor400, 0.7),
              zIndex: 10,
            }}
          >
            {isFavoritesLoading ? (
              <CircularProgress size={20} />
            ) : item?.isFavorite ? (
              <StarIcon
                onClick={e => {
                  e.stopPropagation()
                  handleFavoriteClicked()
                }}
                sx={{
                  cursor: 'pointer',
                  '&:hover': {
                    color: theme => theme.palette.primary.primaryColor400,
                  },
                }}
              />
            ) : (
              <StarBorderIcon
                onClick={e => {
                  e.stopPropagation()
                  handleFavoriteClicked()
                }}
                sx={{
                  cursor: 'pointer',
                  '&:hover': {
                    color: theme => theme.palette.primary.primaryColor400,
                  },
                }}
              />
            )}
          </Stack>
        </Stack>
      </Stack>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        slotProps={{
          paper: { sx: { borderRadius: '8px' } },
        }}
        onClick={e => {
          e.stopPropagation()
        }}
      >
        {isAddingToFolder ? (
          <AddToFolderOptions
            folders={foldersAvailable}
            handleAddToFolder={async (
              folderId: string,
              newFolderName?: string
            ) => {
              if (!item.id) return
              if (newFolderName && folderId === '') {
                await createFolder({
                  folderName: newFolderName,
                  items: [
                    {
                      id: item.id,
                      type: item.type || 'report',
                      name: item.name,
                      reportId: item?.reportId,
                      description: item?.description,
                    },
                  ],
                })
                return
              }

              const folder = foldersAvailable?.find(
                folder => folder.id === folderId
              )

              if (!folder) return
              await updateFolder({
                folderId: folder.id,
                items: [
                  ...folder.items,
                  {
                    id: item.id,
                    type: item.type || 'report',
                    name: item.name,
                    reportId: item?.reportId,
                    description: item?.description,
                  },
                ],
              })
            }}
            handleFolderClose={() => {
              setIsAddingToFolder(false)
              handlePopoverClose()
            }}
          />
        ) : (
          <Stack
            direction='column'
            sx={{
              backgroundColor: '#f0f4f8',
              p: 1,
            }}
            gap={1}
          >
            {!disableEdit && (
              <Stack
                direction={'row'}
                alignItems={'center'}
                sx={{
                  cursor: 'pointer',
                  '&:hover': {
                    color: theme => theme.palette.primary.primaryColor400,
                  },
                }}
                onClick={e => {
                  e.stopPropagation()
                  onRemove()
                  handlePopoverClose()
                }}
                spacing={1}
              >
                <DeleteOutlineOutlinedIcon
                  sx={{
                    mt: 1,
                    fontSize: '20px',
                  }}
                />
                <Typography>Remove</Typography>
              </Stack>
            )}
            <Stack
              direction={'row'}
              alignItems={'center'}
              sx={{
                cursor: 'pointer',
                '&:hover': {
                  color: theme => theme.palette.primary.primaryColor400,
                },
              }}
              onClick={e => {
                e.stopPropagation()
                setIsAddingToFolder(true)
              }}
              spacing={1}
            >
              <CreateNewFolderOutlined
                sx={{
                  mt: '2px',
                  fontSize: '20px',
                }}
              />
              <Typography>Add To Folder</Typography>
            </Stack>
          </Stack>
        )}
      </Popover>
    </Stack>
  )
}
