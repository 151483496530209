import React from 'react'
import {
  Box,
  Dialog,
  Stack,
  Theme,
  Typography,
  IconButton,
} from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
import CloseIcon from '@mui/icons-material/Close'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      padding: 0,
      margin: '16px 0',
    },
    dialogContent: {
      // display: flex won't allow margins to properly collapse,
      // so we use block instead
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      position: 'sticky',
      alignItems: 'center',
      backgroundColor: theme.palette.primary.primaryColor500,
      color: theme.palette.primary.contrastText,
      fontWeight: 600,
      height: '41px !important',
      zIndex: 100,
    },
  })
)

export function TabLayoutModal(props: TabLayoutModalProps) {
  const { isOpen, onClose, children, title } = props

  const classes = useStyles()

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <Stack
        direction='row'
        justifyContent='space-between'
        className={classes.dialogContent}
      >
        <Box>
          {title && typeof title === 'string' && (
            <Box>
              <Typography variant='h3'>{title}</Typography>
            </Box>
          )}
          {title && typeof title !== 'string' && title}
        </Box>
        <Box>
          <IconButton
            color='primary'
            onClick={() => onClose?.()}
            sx={{ padding: 0 }}
          >
            <CloseIcon
              sx={{ fill: theme => theme.palette.background.default }}
            />
          </IconButton>
        </Box>
      </Stack>
      {children}
    </Dialog>
  )
}

export type ChildProps = {
  onSave: () => void
}

export type TabLayoutModalProps = {
  isOpen: boolean
  onClose: () => void
  children: React.ReactElement
  title: string
}
