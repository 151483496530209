import {
  Stack,
  Popover,
  alpha,
  Paper,
  ButtonGroup,
  Button,
  IconButton,
  Tooltip,
  Typography,
  Box,
} from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import {
  AdvancedFilterType,
  BasicFilterType,
  CustomReportFiltersProps,
  DataColumnWithFilters,
  FILTER_TYPES,
} from '../custom-report-table'

import CloseIcon from '@mui/icons-material/Close'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import { DataColumn } from '../../../../models'
import { AdvancedFilter } from './advanced-filter'
import { BasicFilter } from './basic-filter'
import { filterExists, getOperatorSymbol } from '../generate-rdl-file/util'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'

export function CustomReportFilters(props: CustomReportFiltersProps) {
  const { filterOptions, setFilters, getRowValues, gettingRowValues } = props

  const containerRef = useRef<HTMLDivElement>(null)
  const [numFiltersToShow, setNumFiltersToShow] = useState(5)

  const [startIndex, setStartIndex] = useState(0)

  const showIcons = filterOptions.length > numFiltersToShow

  useEffect(() => {
    const handleResize = () => {
      if (containerRef.current) {
        const widthOfParent = containerRef.current.offsetWidth
        const numFilters = Math.floor(widthOfParent / 168)
        setNumFiltersToShow(numFilters)
      }
    }

    window.addEventListener('resize', handleResize)
    handleResize() // Initial calculation

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const handleLeftClick = () => {
    setStartIndex(prevIndex => Math.max(0, prevIndex - numFiltersToShow))
  }

  const handleRightClick = () => {
    setStartIndex(prevIndex =>
      Math.min(
        filterOptions.length - numFiltersToShow,
        prevIndex + numFiltersToShow
      )
    )
  }

  const handleFilterChange = (
    basicFilter: BasicFilterType,
    advancedFilters: AdvancedFilterType[],
    connector: string,
    filterType: string,
    colId: string
  ) => {
    let colToUpdate = filterOptions.find(col => col.id === colId)
    if (!colToUpdate) return

    colToUpdate.basicFilter = basicFilter
    colToUpdate.advancedFilters = advancedFilters
    colToUpdate.filterConnector = connector
    colToUpdate.filterType = filterType

    const updatedFilters = filterOptions.map(col =>
      col.id === colId ? colToUpdate : col
    )

    setFilters(updatedFilters)
  }

  const handleRemoveFilters = (colId: string) => {
    const updatedFilters = filterOptions.map(col =>
      col.id === colId
        ? {
            ...col,
            basicFilter: undefined,
            advancedFilters: [],
            connector: undefined,
            filterType: undefined,
          }
        : col
    )

    setFilters(updatedFilters)
  }

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'center',
        height: '80px',
      }}
    >
      {showIcons && (
        <IconButton
          onClick={handleLeftClick}
          disabled={startIndex === 0}
          sx={{
            color: theme => theme.palette.primary.primaryColor400,
            mb: 0.5,
          }}
        >
          <ChevronLeftIcon
            sx={{
              fontSize: '32px',
            }}
          />
        </IconButton>
      )}
      <Stack
        direction='row'
        justifyContent='flex-start'
        alignItems='flex-end'
        gap={1}
        ref={containerRef}
        sx={{
          width: '100%',
          height: '160px',
          overflow: 'hidden',
          p: 1,
        }}
      >
        {filterOptions
          .slice(startIndex, startIndex + numFiltersToShow)
          .map((filter, i) => (
            <ColumnFilter
              key={i + filter?.id}
              dataColumnWithFilters={filter}
              onFilterApply={(
                basicFilter,
                advancedFilters,
                connector,
                filterType,
                id
              ) => {
                handleFilterChange(
                  basicFilter,
                  advancedFilters,
                  connector,
                  filterType,
                  id
                )
              }}
              onRemoveFilters={id => {
                handleRemoveFilters(id)
              }}
              getRowValues={getRowValues}
              gettingRowValues={gettingRowValues}
            />
          ))}
      </Stack>
      {showIcons && (
        <IconButton
          onClick={handleRightClick}
          disabled={startIndex + numFiltersToShow >= filterOptions.length}
          sx={{
            color: theme => theme.palette.primary.primaryColor400,
            mb: 0.5,
          }}
        >
          <ChevronRightIcon
            sx={{
              fontSize: '32px',
            }}
          />
        </IconButton>
      )}
    </Box>
  )
}

type RemoveFilterButtonProps = {
  onRemoveFilters: () => void
  removeIcon: React.ReactNode
  multipleFilters: boolean
  sx?: any
}

function RemoveFilterButton(props: RemoveFilterButtonProps) {
  const { onRemoveFilters, removeIcon, multipleFilters, sx } = props
  return (
    <Tooltip
      title={`Remove filter${multipleFilters ? 's' : ''}`}
      placement='top'
      arrow={true}
    >
      <IconButton
        sx={{
          ...sx,
          '&:hover': {
            color: theme => alpha(theme.palette.primary.primaryColor400, 0.9),
            backgroundColor: 'transparent',
          },
          mr: '-12px',
        }}
        onClick={e => {
          e.stopPropagation()
          onRemoveFilters()
        }}
      >
        {removeIcon}
      </IconButton>
    </Tooltip>
  )
}

type ColumnFilterProps = {
  dataColumnWithFilters: DataColumnWithFilters
  onFilterApply: (
    basicFilter: BasicFilterType,
    advancedFilters: AdvancedFilterType[],
    connector: string,
    type: string,
    id: string
  ) => void
  onRemoveFilters: (id: string) => void
  getRowValues: (column: DataColumn, search?: string) => Promise<string[]>
  gettingRowValues: boolean
}

export function ColumnFilter(props: ColumnFilterProps) {
  const {
    dataColumnWithFilters,
    onFilterApply,
    onRemoveFilters,
    getRowValues,
    gettingRowValues,
  } = props
  const ref = useRef<HTMLDivElement>(null)

  const [open, setOpen] = useState(false)

  const [currentBasicFilter, setCurrentBasicFilter] =
    useState<BasicFilterType | null>(dataColumnWithFilters?.basicFilter || null)

  const [currentAdvancedFilters, setCurrentAdvancedFilters] = useState(
    dataColumnWithFilters?.advancedFilters || []
  )

  const [connector, setConnector] = useState(
    dataColumnWithFilters?.filterConnector || 'AND'
  )

  const [type, setType] = useState(
    dataColumnWithFilters?.filterType || FILTER_TYPES.BASIC
  )

  const [andValue, setAndValue] = useState(false)
  const [orValue, setOrValue] = useState(false)

  const [showSecondFilter, setShowSecondFilter] = useState(false)

  const handleRemoveFilters = () => {
    setCurrentBasicFilter(null)
    setCurrentAdvancedFilters([])
    onRemoveFilters(dataColumnWithFilters.id)
    setOpen(false)
  }

  const filtersExist =
    filterExists(dataColumnWithFilters) ||
    currentAdvancedFilters.length > 0 ||
    currentBasicFilter !== null

  const filterDescription =
    type === FILTER_TYPES.ADVANCED
      ? currentAdvancedFilters
          .map(
            filter =>
              `${filter?.operator ? getOperatorSymbol(filter.operator) : ''} ${
                filter?.value
              }`
          )
          .join(` ${andValue ? 'AND' : 'OR'} `)
      : Array.isArray(currentBasicFilter?.value)
      ? currentBasicFilter?.value?.join(', ')
      : ''

  return (
    <Tooltip
      title={filterDescription.length > 25 && !open ? filterDescription : ''}
      placement='top'
      arrow={true}
    >
      <Stack
        direction='column'
        justifyContent={'space-between'}
        alignItems={'center'}
        ref={ref}
        onClick={e => {
          e.stopPropagation()
          setOpen(true)
        }}
        sx={{
          width: '160px',
          height: filtersExist ? '60px' : '40px',
          border: theme =>
            `1px solid ${alpha(theme.palette.primary.primaryColor400, 0.5)}`,
          borderRadius: '4px',
          display: 'flex',
          backgroundColor: theme =>
            filtersExist
              ? alpha(theme.palette.primary.primaryColor400, 0.1)
              : 'transparent',
          p: 1,
          '&:hover': {
            backgroundColor: theme =>
              alpha(theme.palette.primary.primaryColor400, 0.05),
            borderColor: theme => theme.palette.primary.primaryColor400,
            boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.25)',
          },
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }}
      >
        <Stack
          direction='row'
          justifyContent={'space-between'}
          alignItems={'center'}
          sx={{
            width: '100%',
          }}
        >
          <Typography
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            {dataColumnWithFilters.name}
          </Typography>
          {filtersExist && (
            <RemoveFilterButton
              onRemoveFilters={handleRemoveFilters}
              sx={{
                width: '32px',
                height: '24px',
              }}
              removeIcon={
                <CloseIcon
                  sx={{
                    fontSize: '20px',
                  }}
                />
              }
              multipleFilters={currentAdvancedFilters.length > 1}
            />
          )}
          <FilterPopover
            anchorEl={ref?.current}
            open={open}
            onClose={e => {
              e.stopPropagation()
              onFilterApply(
                currentBasicFilter,
                currentAdvancedFilters,
                connector,
                type,
                dataColumnWithFilters.id
              )
              setOpen(false)
            }}
          >
            <Stack
              direction='column'
              gap={1}
              sx={{
                width: '200px',
                padding: 0.5,
              }}
            >
              <Stack
                direction={'row'}
                alignItems={'center'}
                justifyContent={'space-between'}
              >
                <FilterTypeButtons
                  selectedFilterType={type}
                  setSelectedFilterType={value => {
                    setType(value)
                  }}
                />
                {filtersExist && (
                  <RemoveFilterButton
                    onRemoveFilters={handleRemoveFilters}
                    removeIcon={<DeleteOutlineIcon />}
                    multipleFilters={currentAdvancedFilters.length > 1}
                  />
                )}
              </Stack>
              {type === FILTER_TYPES.ADVANCED ? (
                <AdvancedFilter
                  dataColumnWithFilters={dataColumnWithFilters}
                  currentAdvancedFilters={currentAdvancedFilters}
                  setCurrentAdvancedFilters={setCurrentAdvancedFilters}
                  setFilterConnector={setConnector}
                  showSecondFilter={showSecondFilter}
                  setShowSecondFilter={setShowSecondFilter}
                  orValue={orValue}
                  setOrValue={setOrValue}
                  andValue={andValue}
                  setAndValue={setAndValue}
                />
              ) : (
                <BasicFilter
                  selectedValues={
                    Array.isArray(currentBasicFilter?.value)
                      ? (currentBasicFilter?.value as string[])
                      : []
                  }
                  setSelectedValues={values => {
                    setCurrentBasicFilter({
                      ...currentBasicFilter,
                      value: values,
                    })
                  }}
                  dataColumnWithFilters={dataColumnWithFilters}
                  getRowValues={getRowValues}
                  gettingRowValues={gettingRowValues}
                />
              )}
              <Typography
                fontSize={'12px'}
                sx={{
                  cursor: 'pointer',
                  '&:hover': {
                    color: theme =>
                      alpha(theme.palette.primary.primaryColor400, 0.9),
                  },
                  alignSelf: 'flex-end',
                  mt: 2,
                }}
                onClick={e => {
                  e.stopPropagation()
                  //Apply filters here too
                  onFilterApply(
                    currentBasicFilter,
                    currentAdvancedFilters,
                    connector,
                    type,
                    dataColumnWithFilters.id
                  )
                  setOpen(false)
                }}
              >
                Apply filter
                {currentAdvancedFilters.length > 1 ? 's' : ''}
              </Typography>
            </Stack>
          </FilterPopover>
        </Stack>
        {filtersExist && (
          <Box
            sx={{
              alignSelf: 'flex-start',
              color: theme => theme.palette.text.secondary,
              fontSize: '12px',
              display: 'block',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              width: '100%',
            }}
          >
            {filterDescription}
          </Box>
        )}
      </Stack>
    </Tooltip>
  )
}

type FilterTypeButtonsProps = {
  selectedFilterType: string
  setSelectedFilterType: (value: string) => void
}

function FilterTypeButtons(props: FilterTypeButtonsProps) {
  const { selectedFilterType, setSelectedFilterType } = props

  const [localState, setLocalState] = useState(selectedFilterType)

  useEffect(() => {
    setLocalState(selectedFilterType)
  }, [selectedFilterType])

  const selectedButtonStyle = {
    backgroundColor: theme => alpha(theme.palette.primary.primaryColor400, 0.3),
    variant: 'contained',
  }

  const nonSelectedButtonStyle = {
    variant: 'outlined',
  }

  const handleButtonClick = (value: string) => {
    setLocalState(value)
    setSelectedFilterType(value)
  }

  return (
    <ButtonGroup
      variant='outlined'
      aria-label='Basic button group'
      sx={{
        alignSelf: 'flex-start',
        mb: 1,
        width: '76%',
      }}
    >
      <Button
        sx={{
          ...(localState === 'basic'
            ? selectedButtonStyle
            : nonSelectedButtonStyle),
          '&:hover': {
            backgroundColor: theme =>
              alpha(theme.palette.primary.primaryColor400, 0.4),
          },
        }}
        onClick={() => handleButtonClick('basic')}
      >
        Basic
      </Button>
      <Button
        sx={{
          ...(localState === 'advanced'
            ? selectedButtonStyle
            : nonSelectedButtonStyle),
          '&:hover': {
            backgroundColor: theme =>
              alpha(theme.palette.primary.primaryColor400, 0.4),
          },
        }}
        onClick={() => handleButtonClick('advanced')}
      >
        Advanced
      </Button>
    </ButtonGroup>
  )
}

type FilterPopoverProps = {
  anchorEl: HTMLElement | null
  children: React.ReactNode
  onClose: (e: any) => void
  open: boolean
}

export function FilterPopover(props: FilterPopoverProps) {
  const { anchorEl, children, onClose, open } = props

  if (!anchorEl) {
    return null
  }

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <Paper
        sx={{
          p: 2,
          boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.25)',
          width: '100%',
          height: '100%',
        }}
      >
        {children}
      </Paper>
    </Popover>
  )
}
