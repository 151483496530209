import {
  Box,
  CircularProgress,
  IconButton,
  Stack,
  Theme,
  Tooltip,
  alpha,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useParams, useHistory } from 'react-router-dom'
import { PbiReport } from './pbi-report'
import {
  useGetBookmarks,
  useGetLicense,
  useGetReports,
  useCloneReport,
  useGetFolders,
  useAddItemsToFavorites,
  useRemoveItemFromFavorites,
} from '../../../hooks'
import { Layout } from '../../../components'
import { useState, useMemo, useCallback, useEffect } from 'react'
import { BookmarkSelector } from '../bookmarks/bookmark-selector'
import { useRecoilState } from 'recoil'
import { bookmarkAtom } from '../../../state'
import { ErrorPage } from './error-page'
import BookmarkDialog from '../bookmarks/bookmark-dialog'
import { ReportOptionsBar } from './report-options-bar'
import {
  ResizableDrawer,
  ResizeState,
} from '../../admin/shared/resizable-drawer'
import { CustomReportTable } from '../create-custom-report/custom-report-table'
import { REPORT_TYPES, WorkspaceInfo } from '../../../models'
import { isOnlyOneUniqueItem } from '../../../utils/uniqueItems'
import { ReportInfoDialog } from './report-info-dialog'
import { Star, StarBorder } from '@mui/icons-material'
import { ToastNotification } from '../../../components/common/Toast'

const useStyles = makeStyles((theme: Theme) => ({
  toolBar: { display: 'flex', justifyContent: 'space-between' },

  drawerPaper: {
    zIndex: 8,
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    flexShrink: 0,
  },

  embedWrapper: {
    height: 'calc(100vh - 40px)',
  },

  embeddedReport: {
    '& iframe': {
      border: 'none',
    },

    flex: 1,
  },
  disablePointerEvents: {
    pointerEvents: 'none',
    flex: 1,
    filter: 'blur(2px)',
    '& iframe': {
      border: 'none',
    },
  },
}))

export function ReportView() {
  const classes = useStyles()
  const routeParams = useParams<any>()
  const reportId = routeParams.id
  const bookmarkId = routeParams.bookmark
  const { data: reports, isLoading, refetch: reloadReports } = useGetReports()
  const { data: bookmarks, isLoading: bookmarksLoading } =
    useGetBookmarks(reportId)
  const { data: license, isLoading: licenseLoading } = useGetLicense()
  const [bookmarkState, setBookmarkState] = useRecoilState(bookmarkAtom)
  const [openReportInfoDialog, setOpenReportInfoDialog] = useState(false)
  const { data: folders, isLoading: foldersLoading } = useGetFolders()
  const { mutateAsync: addToFavorites, isLoading: isAddingToFavorites } =
    useAddItemsToFavorites()
  const {
    mutateAsync: removeFromFavorites,
    isLoading: isRemovingFromFavorites,
  } = useRemoveItemFromFavorites()
  const {
    mutateAsync: cloneReport,
    isLoading: isCloningReport,
    isSuccess: successCloningReport,
    isError: errorCloningReport,
  } = useCloneReport()
  const history = useHistory()

  const isFavorites = folders
    ?.find(folder => folder?.isFavorites)
    ?.items?.find(item => item.id === reportId)

  const showFavorites = !!reportId

  const ownedReports = reports?.ownedReports || []
  const sharedReports = reports?.sharedReports || []
  const reportGroupReports = reports?.groupedReports || []
  const defaultReports = reports?.defaultReports || []
  const allReports = [
    ...ownedReports,
    ...sharedReports,
    ...reportGroupReports,
    ...defaultReports,
  ]

  const [bookmarkToEdit, setBookmarkToEdit] = useState(null)
  const [isDialogOpen, setIsDialogOpen] = useState(false)

  const [editMode, setEditMode] = useState(false)
  const toggleEditMode = () => {
    setEditMode(o => !o)
  }

  const selectedReport =
    allReports?.find(report => report.id === reportId) || null

  const [showCopyDialog, setShowCopyDialog] = useState(false)
  const [copyReport, setCopyReport] = useState<{
    name: string
    description: string
    shareWithLicense: boolean
    workspace: WorkspaceInfo
  }>(null)

  const [toastOpen, setToastOpen] = useState(false)
  const [toastMessage, setToastMessage] = useState('')
  const [toastVariant, setToastVariant] = useState<
    'primary' | 'success' | 'warning' | 'error'
  >('success')

  const [isFavoritesLoading, setIsFavoritesLoading] = useState(false)

  const [resizableState, setResizableState] = useState<ResizeState>({
    isResizing: false,
    lastDownX: 0,
    newWidth: { width: 280 },
  })

  const [hasRendered, setHasRendered] = useState(false)

  const [isResizing, setIsResizing] = useState(false)

  useEffect(() => {
    setIsResizing(true)

    setTimeout(() => {
      setIsResizing(false)
    }, 300)
  }, [bookmarkState?.panelOpen])

  useEffect(() => {
    //if resizableState.isResizing is true then set isResizing to true if it is false wait 1 second then set to false
    if (resizableState.isResizing) {
      setIsResizing(true)
    } else {
      setTimeout(() => {
        setIsResizing(false)
      }, 400)
    }
  }, [resizableState.isResizing])

  useEffect(() => {
    if (bookmarksLoading || !bookmarks) return
    const allBookmarks = [
      ...bookmarks.licenseBookmarks,
      ...bookmarks.sharedBookmarks,
      ...bookmarks.ownedBookmarks,
    ]

    if (!bookmarkId || bookmarkId === bookmarkState?.selectedBookmark?.id)
      return
    const bookmark = allBookmarks.find(b => b.id === bookmarkId)
    if (!bookmark) return
    setBookmarkState({
      ...bookmarkState,
      selectedBookmark: bookmark,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookmarks, bookmarksLoading, bookmarkId])

  const toggleDialog = useCallback(() => {
    setIsDialogOpen(o => !o)
  }, [])
  const showBackButton = !isOnlyOneUniqueItem(allReports)

  const reportEmbedSettings = useMemo(
    () => ({
      filterPaneEnabled: selectedReport?.filterPaneEnabled,
      showPageNavigation: selectedReport?.showPageNavigation,
      defaultPageName: selectedReport?.defaultPageName,
    }),
    [selectedReport]
  )

  useEffect(() => {
    const onSave = async () => {
      if (!copyReport || !copyReport.workspace?.id) return

      const newReport = await cloneReport({
        sourceReportId: reportId,
        newName: copyReport.name,
        description: copyReport?.description,
        sharedWithLicense: copyReport?.shareWithLicense,
        licenseNodeId: license.id,
        reportGroupId: selectedReport?.reportGroupId || null,
      })

      if (successCloningReport) {
        setToastMessage('Report cloned')
        setToastVariant('success')
      } else if (errorCloningReport) {
        setToastMessage('Error cloning report')
        setToastVariant('error')
      }

      await reloadReports()

      setToastOpen(true)
      setEditMode(false)
      setCopyReport(null)
      setShowCopyDialog(false)
      history.push(`/reports/${newReport.id}`)
    }
    onSave()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [copyReport])

  const isDataset = selectedReport?.type === REPORT_TYPES.DATASET

  const isCustomPaginatedReport =
    selectedReport?.type === REPORT_TYPES.CUSTOM_PAGINATED

  const favoritesButton = !showFavorites ? null : isAddingToFavorites ||
    isRemovingFromFavorites ||
    isFavoritesLoading ||
    foldersLoading ? (
    <Box
      sx={{
        height: '40px',
        width: '40px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <CircularProgress size={20} />
    </Box>
  ) : (
    <Tooltip title={isFavorites ? 'Unfavorite' : 'Favorite'} arrow={true}>
      <IconButton
        component='button'
        color='inherit'
        aria-label='menu'
        onClick={async () => {
          setIsFavoritesLoading(true)
          isFavorites
            ? await removeFromFavorites({ itemId: reportId })
            : await addToFavorites({
                item: {
                  id: reportId,
                  type: selectedReport?.type,
                  name: selectedReport?.name,
                  description: selectedReport?.description,
                },
              })

          await setTimeout(() => {
            setIsFavoritesLoading(false)
          }, 650)
        }}
        size='small'
        sx={{
          height: '40px',
          width: '40px',
        }}
      >
        {isFavorites ? (
          <Star
            sx={{
              color: theme => alpha(theme.palette.primary.primaryColor400, 0.7),
              '&:hover': {
                color: theme => theme.palette.primary.primaryColor400,
                backgroundColor: 'transparent',
              },
            }}
          />
        ) : (
          <StarBorder
            sx={{
              color: theme => alpha(theme.palette.primary.primaryColor400, 0.7),
              '&:hover': {
                color: theme => theme.palette.primary.primaryColor400,
                backgroundColor: 'transparent',
              },
            }}
          />
        )}
      </IconButton>
    </Tooltip>
  )

  return (
    <Layout
      title={selectedReport?.name || ''}
      infoButtonTooltip='Report Info'
      infoButtonOnClick={() => setOpenReportInfoDialog(true)}
      favoritesButton={favoritesButton}
      showBackButton={showBackButton}
      hideLogo
      hideBackground
      fullWidth
      reportOptions={
        <Stack
          direction='column'
          // className={classes.embedWrapper}
          sx={{
            display: 'flex',
            flex: 1,
            marginRight: bookmarkState.panelOpen
              ? `${resizableState.newWidth.width}px`
              : 0,
            width: bookmarkState.panelOpen
              ? `calc(100vw - ${resizableState.newWidth.width}px)`
              : '100%',
            transition: theme =>
              theme.transitions.create(['margin', 'width'], {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
              }),
          }}
        >
          {!isDataset && (
            <ReportOptionsBar
              reportId={reportId}
              isOwner={ownedReports?.some(report => report.id === reportId)}
              isAdmin={license?.isAdmin}
              editMode={editMode}
              toggleEditMode={toggleEditMode}
              showCopyDialog={showCopyDialog}
              setShowCopyDialog={setShowCopyDialog}
              setCopyReport={setCopyReport}
              reportType={selectedReport?.type || ''}
              hasRendered={hasRendered}
            />
          )}
        </Stack>
      }
    >
      {isLoading || bookmarksLoading || licenseLoading || isCloningReport ? (
        <Box
          sx={{
            width: 'calc(100vw)',
            height: 'calc(100vh - 400px)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress
            sx={{
              fontSize: 40,
            }}
          />
        </Box>
      ) : !selectedReport ? (
        <ErrorPage>
          Error: You do not have access to this report or the report does not
          exist
        </ErrorPage>
      ) : (
        <>
          {isDataset || (isCustomPaginatedReport && editMode) ? (
            <Box
              className={classes.embedWrapper}
              sx={{
                display: 'flex',
                width: '100%',
              }}
            >
              <CustomReportTable
                currentReportId={reportId}
                name={selectedReport?.name}
                report={selectedReport}
              />
            </Box>
          ) : (
            <Stack
              direction='column'
              // className={classes.embedWrapper}
              sx={{
                display: 'flex',
                flex: 1,
                height: 'calc(100vh - 90px)',
                marginRight: bookmarkState.panelOpen
                  ? `${resizableState.newWidth.width}px`
                  : 0,
                width: bookmarkState.panelOpen
                  ? `calc(100vw - ${resizableState.newWidth.width}px)`
                  : '100%',
                transition: theme =>
                  theme.transitions.create(['margin', 'width'], {
                    easing: theme.transitions.easing.easeOut,
                    duration: theme.transitions.duration.enteringScreen,
                  }),
              }}
            >
              <ReportInfoDialog
                report={selectedReport}
                open={openReportInfoDialog}
                onClose={() => setOpenReportInfoDialog(false)}
              />

              <PbiReport
                setHasRendered={setHasRendered}
                reportId={reportId}
                reportEmbedSettings={reportEmbedSettings}
                embedType='report'
                className={
                  isResizing
                    ? classes.disablePointerEvents
                    : classes.embeddedReport
                }
                editMode={editMode}
                setShowCopyDialog={setShowCopyDialog}
                copyReport={copyReport}
                isResizing={isResizing}
                handleSave={() => {
                  setToastMessage('Report saved')
                  setToastVariant('success')
                  setToastOpen(true)
                }}
              />
            </Stack>
          )}
          {bookmarkState.panelOpen && (
            <ResizableDrawer
              open={bookmarkState.panelOpen}
              resizeState={resizableState}
              setResizeState={setResizableState}
              minWidth={100}
              maxWidth={1000}
            >
              <div
                style={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <BookmarkSelector
                  bookmarks={bookmarks}
                  bookmarksLoading={bookmarksLoading}
                  reportId={reportId}
                  licenseId={license.id}
                  onAddClick={() => {
                    setBookmarkToEdit(null)
                    toggleDialog()
                  }}
                  onEdit={bookmark => {
                    setIsDialogOpen(true)
                    setBookmarkToEdit(bookmark)
                  }}
                  drawerWidth={resizableState?.newWidth?.width}
                />
              </div>
            </ResizableDrawer>
          )}
          <BookmarkDialog
            open={isDialogOpen}
            onClose={() => setIsDialogOpen(false)}
            reportId={reportId}
            bookmark={bookmarkToEdit}
          />
        </>
      )}
      <ToastNotification
        open={toastOpen}
        onClose={() => setToastOpen(false)}
        message={toastMessage}
        variant={toastVariant}
        autoTimeout={2000}
      />
    </Layout>
  )
}
