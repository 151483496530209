import { useEffect, useState } from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import {
  ListItemText,
  Collapse,
  List,
  ListItem,
  IconButton,
  alpha,
  Theme,
  ButtonBase,
} from '@mui/material'
import { FolderItem } from '../../../models'
import { NavigationLink } from './navigation-link'
import { highlightText } from './highlight-text'
import { getItemPath } from '../../../pages/reports/report-dashboard/folders/folder-utilities'
import { makeStyles, createStyles } from '@mui/styles'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    listItem: {
      borderTop: '1px solid #F2F2F2',
      height: '30px',
      overflow: 'hidden',
      fontSize: theme.typography.fontSize,
      '&:hover': {
        backgroundColor: alpha(theme.palette.primary.primaryColor100, 1),
      },
    },
    listItemText: {
      fontSize: theme.typography.fontSize,
      fontWeight: '600',
    },
  })
)

export function FolderNav(props: FolderNavProps) {
  const { title, items, subFolder, searchText } = props
  const classes = useStyles()
  const [open, setOpen] = useState(searchText.length > 2)

  useEffect(() => {
    if (searchText.length) {
      setOpen(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText])

  const handleClick = () => {
    setOpen(!open)
  }

  return (
    <List disablePadding>
      <ButtonBase sx={{ width: '100%', font: 'inherit' }}>
        <ListItem
          onClick={handleClick}
          className={classes.listItem}
          sx={{
            pl: subFolder ? 2 : 1,
          }}
        >
          <IconButton onClick={handleClick}>
            {open ? <ExpandMoreIcon /> : <KeyboardArrowRightIcon />}
          </IconButton>
          {/*icon*/}
          <ListItemText
            className={classes.listItemText}
            primary={highlightText(title, searchText)}
            disableTypography={true}
          />
        </ListItem>
      </ButtonBase>

      <Collapse in={open} timeout='auto' unmountOnExit>
        <List component='div' sx={{ padding: 0 }}>
          {items?.map((item, index) => (
            <NavigationLink
              key={`navlink-${item.id}-${index}`}
              path={getItemPath(item)}
              dense
              searchText={searchText}
              itemType={item.type}
              description={item.description}
              sx={{
                pl: subFolder ? 1 : 0.5,
              }}
            >
              {item.name}
            </NavigationLink>
          ))}
        </List>
      </Collapse>
    </List>
  )
}

type FolderNavProps = {
  title: string
  items: FolderItem[]
  icon: React.ReactNode
  subFolder?: boolean
  searchText: string
}
